import styled from 'styled-components';

import Title from '../Title';

const StyledFooter = styled.footer`
  min-height: 545px;
  display: flex;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.blue};
  padding-left: 200px;
  margin-top: 100px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column-reverse;
    padding-left: 0;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin-top: 0;
  }
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  min-width: 300px;
`;

const StyledTitle = styled(Title)`
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

const StyledAnchor = styled.a`
  font-family: 'OpenSansRegular';
  font-size: 20px;
  line-height: 30px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin: 14px 0;

  &:hover {
    cursor: pointer;
    font-family: 'OpenSansSemiBold';
  }
`;

export { StyledCol, StyledTitle, StyledAnchor, StyledFooter };
