import styled from 'styled-components';

const StyledTabs = styled.div`
  background: white;
  width: 100%;
  height: fit-content;
  margin: 20px auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInnerTabs = styled.ul`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  list-style-type: none;
`;

export { StyledTabs, StyledInnerTabs };
