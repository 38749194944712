import { StyledLink } from './styles';

type LinkProps = {
  title: string;
  url: string;
};

const Link = ({ title, url }: LinkProps) => <StyledLink href={url}>{title}</StyledLink>;

export default Link;
