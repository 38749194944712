import { useTranslation } from 'react-i18next';

import Link from '../../../components/ui/Link';
import Paragraph from '../../../components/ui/Paragraph';
import { contactInfo } from '../../../utils/helpers/contact';

import { StyledContactInfo } from './styles';

const ContactInfo = () => {
  const { t } = useTranslation('translation');
  return (
    <StyledContactInfo>
      <Paragraph content={t('contact.info.address')} isBold variant={1} />
      <Paragraph content={contactInfo.address} variant={2} />
      <Paragraph content={t('contact.info.email')} isBold variant={1} />
      <Link title={contactInfo.email} url={contactInfo.emailLink} />
      <Paragraph content={t('contact.info.phone')} isBold variant={1} />
      <Paragraph content={contactInfo.phone} variant={2} />
    </StyledContactInfo>
  );
};

export default ContactInfo;
