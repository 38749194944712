import { AnimatePresence } from 'framer-motion';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { animationIn, animationOut } from '../../../utils/helpers/animations';
import { promiseHandleClick } from '../../../utils/helpers/navbar';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';

import {
  StyledSelectContainer,
  StyledSelect,
  StyledOption,
  StyledIconWrapper,
  StyledUsername,
  StyledOptions,
} from './styles';

type SelectOption = {
  id: number;
  label: string;
  handleAction: () => Promise<void> | void;
};

interface SelectProps extends React.PropsWithChildren {
  options: SelectOption[];
  defaultValue: string;
}

const Select = ({ options, defaultValue, children }: SelectProps) => {
  const launcherNode = useRef<HTMLDivElement>(null);
  const reference = useRef<HTMLDivElement>(null);
  const { hidden, setHidden } = useClickOutside<HTMLDivElement>(reference, launcherNode);
  const { t } = useTranslation('translation');

  const handleClick = () => {
    setHidden((previous) => !previous);
  };
  return (
    <StyledSelectContainer onClick={handleClick} ref={launcherNode}>
      {Boolean(children) && <StyledIconWrapper>{children}</StyledIconWrapper>}
      <StyledSelect>
        <StyledUsername visible={!hidden}>{defaultValue}</StyledUsername>
        <AnimatePresence>
          {Boolean(!hidden) && (
            <StyledOptions animate={animationIn} exit={animationOut} initial={animationOut}>
              <div ref={reference}>
                {options.map((option) => (
                  <StyledOption key={option.id} onClick={promiseHandleClick(option.handleAction)}>
                    {t(option.label)}
                  </StyledOption>
                ))}
              </div>
            </StyledOptions>
          )}
        </AnimatePresence>
      </StyledSelect>
    </StyledSelectContainer>
  );
};

export default Select;
