import { useTranslation } from 'react-i18next';

import MoreInformation from '../../../components/ui/MoreInformation';

import { StyledTitle, StyledSubtitle, StyledText, StyledSection } from './styles';

type PostgradeMoreInfoProps = {
  description: string;
  isPurchasable?: boolean;
  isDownloadable?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const blueButtonFunction = () => {};

const PostgradeMoreInfo = ({ description, isDownloadable = false, isPurchasable = false }: PostgradeMoreInfoProps) => {
  const { t } = useTranslation('translation');
  const blueButton = t('courses.postgrade.viewEditions');
  return (
    <MoreInformation
      blueButton={blueButton}
      blueButtonFunction={blueButtonFunction}
      isDownloadable={isDownloadable}
      isPurchasable={isPurchasable}
    >
      <StyledTitle>{t('courses.view.moreInfo.title')}</StyledTitle>
      <StyledSection>
        <StyledSubtitle>{t('courses.postgrade.editions')}</StyledSubtitle>
        <StyledText>{description}</StyledText>
      </StyledSection>
    </MoreInformation>
  );
};

export default PostgradeMoreInfo;
