import { createGlobalStyle } from 'styled-components';

import OpenSansFont from '../../assets/fonts/OpenSans/fontFace';
import RalewayFont from '../../assets/fonts/Raleway/fontFace';

export const globalStyle = createGlobalStyle`
  ${OpenSansFont};
  ${RalewayFont};

  body{
    margin: 0;
  }
`;
