import './utils/i18n';
import ThemeProvider from './utils/theme';
import Pages from './pages';
import { AuthProvider } from './context/Auth/Provider';

const App = () => (
  <ThemeProvider>
    <AuthProvider>
      <Pages />
    </AuthProvider>
  </ThemeProvider>
);

export default App;
