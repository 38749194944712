import styled from 'styled-components';

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.lightGray};
  width: 340px;
  min-height: 360px;
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 30px;
`;

export { StyledInfo };
