import styled from 'styled-components';

export const StyledDescription = styled.div`
  width: 50%;
  margin: 7% 0 15% 7%;
  white-space: pre-line;
`;

export const StyledTitle = styled.h1`
  color: ${({ theme }) => theme.colors.blue};
  font-family: 'RalewayBold';
  font-style: normal;
  font-size: 48px;
  line-height: 130%;
  white-space: pre-line;
`;
