import styled from 'styled-components';

const StyledLoginForm = styled.div`
  margin: 10px 73px 20px 73px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInputContainer = styled.div`
  margin: 10px 20px 0 20px;
`;

const StyledButtonContainer = styled.div`
  margin: 20px auto;
  width: fit-content;
`;

const StyledErrorMessage = styled.p`
  color: red;
  margin: 0;
  font-family: 'RalewaySemiBold';
  text-transform: capitalize;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 12px;
  left: 0px;
`;

export { StyledLoginForm, StyledInputContainer, StyledButtonContainer, StyledErrorMessage };
