// eslint-disable-next-line import/no-namespace
import * as yup from 'yup';
import type { FieldValues } from 'react-hook-form';

const loginFormSchema = yup.object({
  email: yup.string().email().required('Required'),
  password: yup.string().required('Required'),
});

export interface ILoginForm extends FieldValues {
  email: string;
  password: string;
}

export default loginFormSchema;
