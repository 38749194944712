import type { FieldValues } from 'react-hook-form';
// eslint-disable-next-line import/no-namespace
import * as yup from 'yup';

const contactFormSchema = yup.object({
  name: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  reason: yup.string().required('Required'),
  message: yup.string().required('Required'),
});

export interface IContactForm extends FieldValues {
  name: string;
  email: string;
  reason: string;
  message: string;
}
export default contactFormSchema;
