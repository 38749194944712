/* eslint-disable @typescript-eslint/no-misused-promises */
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';

import type { ISignUpForm } from '../../../utils/schemas/SignUp';
import signUpFormSchema from '../../../utils/schemas/SignUp';
import Form from '../../../components/ui/Form';
import { signUp } from '../../../utils/api/auth';
import { useFetch } from '../../../utils/hooks/useFetch';
import Modal from '../../../components/ui/Modal';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';
import ModalInput from '../../../components/ui/Modal/ModalInput';
import ModalButton from '../../../components/ui/Modal/ModalButton';
import Loading, { AnimatedPage as AnimatedContent } from '../../../components/ui/LoadingScreen';

import { StyledSignUpForm, StyledErrorMessage } from './styles';
import SignUpSuccess from './Success';

const SignUpForm = () => {
  const { t } = useTranslation('translation');
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ISignUpForm>({
    resolver: yupResolver(signUpFormSchema),
  });
  const reference = useRef<HTMLDivElement>(null);
  const { hidden, setHidden } = useClickOutside<HTMLDivElement>(reference);

  const { error, success, doFetch, loading } = useFetch<string, ISignUpForm>(signUp);

  const submitHandler = () => {
    void doFetch({
      name: getValues('name'),
      email: getValues('email'),
      password: getValues('password'),
      passwordConfirmation: getValues('passwordConfirmation'),
    });
  };

  useEffect(() => {
    if (success) {
      setHidden(false);
    }
  }, [success, setHidden]);

  if (loading) return <Loading />;

  return (
    <AnimatedContent>
      <StyledSignUpForm>
        <Form handler={handleSubmit(submitHandler)}>
          <ModalInput errors={errors} labelText={t('forms.signUp.name')} name="name" registerProps={register('name')} />
          <ModalInput
            errors={errors}
            labelText={t('forms.signUp.email')}
            name="email"
            registerProps={register('email')}
          />
          <ModalInput
            errors={errors}
            labelText={t('forms.signUp.password')}
            name="password"
            registerProps={register('password')}
            type="password"
          />
          <ModalInput
            errors={errors}
            labelText={t('forms.signUp.passwordConfirmation')}
            name="passwordConfirmation"
            registerProps={register('passwordConfirmation')}
            type="password"
          />
          <ModalButton color="blue" text={t('forms.signUp.signUp')} type="submit" />
        </Form>

        {Boolean(error) && <StyledErrorMessage>{error}</StyledErrorMessage>}
        <Modal isHidden={hidden} reference={reference} setHidden={setHidden}>
          <SignUpSuccess />
        </Modal>
      </StyledSignUpForm>
    </AnimatedContent>
  );
};

export default SignUpForm;
