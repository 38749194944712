import { useTranslation } from 'react-i18next';

import { handleRedirection } from '../../../utils/helpers/editions';
import { BlueButton } from '../basicButton';
import Divider from '../Divider';

import {
  StyledEditionCard,
  StyledActionWrapper,
  StyledTitle,
  StyledSubtitle,
  StyledParagraph,
  StyledCaption,
  StyledLabel,
} from './styles';

interface EditionCardProps {
  title: string;
  date: string;
  format: string;
  place: string;
  url: string;
}

const EditionCard = ({ title, date, format, place, url }: EditionCardProps) => {
  const { t } = useTranslation('translation');

  return (
    <StyledEditionCard>
      <StyledTitle content={title} variant={2} />
      <StyledSubtitle content={date} variant={2} />
      <Divider />
      <StyledLabel content={t('courses.cards.format')} isBold variant={1} />
      <StyledParagraph content={format} variant={1} />
      <StyledLabel content={t('courses.cards.place')} isBold variant={1} />
      <StyledParagraph content={place} variant={1} />
      <StyledActionWrapper>
        <StyledCaption content={t('courses.cards.moreInfo')} isBold variant={2} />
        <BlueButton onClick={handleRedirection(url)} text={t('courses.cards.button')} />
      </StyledActionWrapper>
    </StyledEditionCard>
  );
};

export default EditionCard;
