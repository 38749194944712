/* eslint-disable import/no-namespace */

import type { FieldValues } from 'react-hook-form';
import * as yup from 'yup';

const forgotPasswordFormSchema = yup.object({
  email: yup.string().email().required('Required'),
});

export interface IForgotPasswordForm extends FieldValues {
  email: string;
}

export { forgotPasswordFormSchema };
