import { Route, BrowserRouter, Routes } from 'react-router-dom';

import Layout from './Layout';
import HomePage from './Home';
import CourseDetailPage from './Course';
import LoginPage from './Auth/Login';
import SignUpPage from './Auth/SignUp';
import ResetPasswordPage from './Auth/ResetPassword';
import ChangePasswordPage from './Auth/ChangePassword';
import ContactPage from './Contact';
import ProtocolsPage from './Protocols';
import LegalAgreementPage from './Legals/Agreement';
import PrivacyPolicyPage from './Legals/PrivacyPolicy';
import CookiesPage from './Legals/CookiesPolicy';
import PostgradeDetailPage from './Postgrade';
import PaymentResponsePage from './Payment';
import PaymentsTable from './Payment/Table';

const Pages = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Layout />} path="/">
        <Route element={<HomePage />} index />
        <Route element={<LoginPage />} path="/login" />
        <Route element={<SignUpPage />} path="/signUp" />
        <Route element={<ResetPasswordPage />} path="/resetPassword/:token" />
        <Route element={<ChangePasswordPage />} path="/changePassword" />
        <Route element={<ContactPage />} path="/contact" />
        <Route element={<ProtocolsPage />} path="/tools/protocols" />
        <Route element={<LegalAgreementPage />} path="/legalAgreement" />
        <Route element={<PrivacyPolicyPage />} path="/privacyPolicy" />
        <Route element={<CookiesPage />} path="/cookies" />
        <Route element={<CourseDetailPage />} path="/courses/:id" />
        <Route element={<PostgradeDetailPage />} path="/postgrades/:id" />
        <Route element={<PaymentResponsePage />} path="/payment/success" />
        <Route element={<PaymentResponsePage isSuccess={false} />} path="/payment/error" />
        <Route element={<PaymentsTable />} path="/payment/v=dQw4w9WgXcQ" />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Pages;
