import { useEffect, useState } from 'react';

import { getCourseHeaders } from '../../../../../utils/api/layout';
import type { CourseHeadersType } from '../../../../../utils/api/layout';
import { dropDelay, moveUpwards, dropDown } from '../../../../../utils/helpers/animations';
import type { DropDownProps } from '..';

import { StyledDropDown, StyledCourseContainer, StyledCourseList } from './styles';
import HeaderList from './HeaderList';

const CoursesDropDown = ({ closeMenu, reference }: DropDownProps) => {
  const [headerList, setHeaderList] = useState<CourseHeadersType[]>([]);

  useEffect(() => {
    const getHeaderList = async () => {
      const { data } = await getCourseHeaders();
      if (data) setHeaderList(data);
    };
    void getHeaderList();
  }, []);

  if (headerList.length === 0) {
    return null;
  }

  return (
    <StyledCourseContainer ref={reference}>
      <StyledDropDown animate={dropDown} exit={moveUpwards} initial={moveUpwards} transition={dropDelay}>
        <StyledCourseList animate={dropDown} exit={moveUpwards} initial={moveUpwards} transition={dropDelay}>
          <HeaderList closeMenu={closeMenu} headerList={headerList} />
        </StyledCourseList>
      </StyledDropDown>
    </StyledCourseContainer>
  );
};

export default CoursesDropDown;
