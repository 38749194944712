import { useState } from 'react';

import { slideIn, slideOut } from '../helpers/animations';

const useSlider = (end: number) => {
  const [activeSlide, setActiveSlide] = useState(1);

  const slideRight = () => {
    if (activeSlide > 1) setActiveSlide((slide) => slide - 1);
    else setActiveSlide(end);
  };

  const slideLeft = () => {
    if (activeSlide < end) setActiveSlide((slide) => slide + 1);
    else setActiveSlide(1);
  };

  const isActive = (slide: number) => activeSlide === slide;

  const animation = (slide: number) => (isActive(slide) ? slideIn : slideOut);

  return { slideRight, slideLeft, animation, isActive };
};
export { useSlider };
