/* eslint-disable camelcase, @typescript-eslint/naming-convention */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BlueButton } from '../../components/ui/basicButton';
import Title from '../../components/ui/Title';
import { sendPaymentGatewayResponse } from '../../utils/api/payment';
import { isEmpty } from '../../utils/helpers/base';
import SuccessIcon from '../../assets/images/successIcon.png';
import ErrorIcon from '../../assets/images/errorIcon.png';

import { StyledPaymentIcon, StyledPaymentResponse } from './styles';

type PaymentResponsePageProps = {
  isSuccess?: boolean;
};

const PaymentResponsePage = ({ isSuccess = true }: PaymentResponsePageProps) => {
  const { Ds_MerchantParameters = '', Ds_Signature = '' } = useParams();
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const status = isSuccess ? { icon: SuccessIcon, slug: 'success' } : { icon: ErrorIcon, slug: 'error' };

  const handleClick = () => {
    navigate('/');
  };

  useEffect(() => {
    if (isSuccess && !isEmpty(Ds_MerchantParameters) && !isEmpty(Ds_Signature))
      void sendPaymentGatewayResponse(Ds_MerchantParameters, Ds_Signature);
  }, [isSuccess, Ds_MerchantParameters, Ds_Signature]);

  return (
    <StyledPaymentResponse>
      <StyledPaymentIcon src={status.icon} />
      <Title content={t(`payment.${status.slug}`)} variant={1} />
      <BlueButton isLarge onClick={handleClick} text={t('payment.continue')} />
    </StyledPaymentResponse>
  );
};

export default PaymentResponsePage;
