export const colors = {
  lightBlue: '#F4F9FD',
  skyBlue: '#74ACDA',
  blue: '#000B3D',
  darkGray: '#676767',
  lightGray: '#ECF0F1',
  gray: '#CBCACA',
  white: '#FFFFFF',
  transparentBlue: 'rgba(0, 11, 61, 0.8);',
  black: '#1C1B1F',
  lightLightBlue: '#D6E9FA',
};

export type ColorsType = typeof colors;
