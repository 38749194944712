import EditionCard from '../../../components/ui/EditionCard';
import SelectableList from '../../../components/ui/SelectableList';
import DescriptionContent from '../../../components/ui/SelectableList/DescriptionContent';
import type { PostgradeContents, PostgradeEditions, PostGradeTabsContents } from '../../../utils/helpers/postgrade';

import { StyledEditionCardsContainer } from './styles';

type TabContentManagerProps = {
  activeTab: string;
  content: PostGradeTabsContents[];
};

const cardFiller = (card: PostgradeEditions) => (
  <EditionCard
    date={card.date}
    format={card.format}
    key={card.title}
    place={card.location}
    title={card.title}
    url={card.url}
  />
);

const TabContentManager = ({ activeTab, content }: TabContentManagerProps) => {
  if (activeTab === 'editions') {
    const mappedCards = content.map((card) => cardFiller(card as PostgradeEditions));
    return <StyledEditionCardsContainer>{mappedCards}</StyledEditionCardsContainer>;
  }
  return (
    <SelectableList activeTab={activeTab} content={content as PostgradeContents[]}>
      <DescriptionContent />
    </SelectableList>
  );
};

export default TabContentManager;
