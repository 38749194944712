import type { FieldErrorsImpl, UseFormRegisterReturn } from 'react-hook-form';

import Input from '../../BasicInput';

import { StyledModalInputContainer } from './styles';

type InputProps = {
  name: string;
  type?: 'email' | 'password' | 'tel' | 'text';
  labelText: string;
  errors?: FieldErrorsImpl;
  registerProps: UseFormRegisterReturn;
};

const ModalInput = ({ name, type = 'text', labelText, errors, registerProps }: InputProps) => {
  return (
    <StyledModalInputContainer>
      <Input errors={errors} labelText={labelText} name={name} registerProps={registerProps} type={type} />
    </StyledModalInputContainer>
  );
};

export default ModalInput;
