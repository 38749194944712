import styled from 'styled-components';

const sharedStyles = `
  border: 1px solid;
  text-align: left;
  padding: 8px;
`;

const StyledTableData = styled.td`
  ${sharedStyles};
  font-family: 'RalewayLight';
  color: ${({ theme }) => theme.colors.blue};
  border-color: ${({ theme }) => theme.colors.gray};
`;

const StyledTableHeaders = styled.th`
  ${sharedStyles};
  font-family: 'RalewaySemiBold';
  color: ${({ theme }) => theme.colors.blue};
  border-color: ${({ theme }) => theme.colors.gray};
`;

const StyledTableRow = styled.tr`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.lightLightBlue};
  }
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 90%;
  margin: auto;
`;

export { StyledTableData, StyledTableHeaders, StyledTableRow, StyledTable };
