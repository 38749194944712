import styled from 'styled-components';

export const StyledFormGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const StyledContactForm = styled.div`
  max-width: 50%;
  flex-grow: 2;
`;

export const StyledIcon = styled.img`
  width: 100px;
  height: 100px;
  margin: auto;
  display: block;
`;
