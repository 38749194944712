import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Divider from '../../../../components/ui/Divider';
import ModalButton from '../../../../components/ui/Modal/ModalButton';
import ModalContentContainer from '../../../../components/ui/Modal/ModalContentContainer';
import ModalDescription from '../../../../components/ui/Modal/ModalDescription';
import ModalTitle from '../../../../components/ui/Modal/ModalTitle';

const ChangePasswordSuccess = () => {
  const { t } = useTranslation('translation');
  const nav = useNavigate();

  const handleHomeClick = () => {
    nav('/');
  };
  const handleLoginClick = () => {
    nav('/login');
  };

  return (
    <ModalContentContainer>
      <ModalTitle content={t('forms.changePassword.success.title')} />
      <Divider />
      <ModalDescription content={t('forms.changePassword.success.description')} />
      <ModalButton
        color="blue"
        onClick={handleLoginClick}
        text={t('forms.changePassword.success.login')}
        type="button"
      />
      <ModalButton
        color="transparent"
        onClick={handleHomeClick}
        text={t('forms.changePassword.success.home')}
        type="button"
      />
    </ModalContentContainer>
  );
};

export default ChangePasswordSuccess;
