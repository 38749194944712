import postgrade from '../../data/postgradeOne.json';

type PostgradeType = typeof postgrade;

const getPostgrade = async (id: string) => {
  console.log(id);
  return await Promise.resolve(postgrade);
};

export { getPostgrade };
export type { PostgradeType };
