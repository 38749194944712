import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const StyledToolsDropdown = styled(motion.div)(
  ({ theme: { colors, shadows, breakpoints } }) => css`
    padding: 0 1rem;
    background: ${colors.blue};
    position: relative;
    width: 200px;
    left: 50%;
    top: 16px;
    z-index: 999;
    box-shadow: ${shadows.medium};

    @media screen and (max-width: ${breakpoints.sm}) {
      width: 100%;
      left: 0;
    }
    @media screen and (min-width: 1601px) {
      top: 0;
    }
  `,
);

export { StyledToolsDropdown };
