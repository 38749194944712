/* eslint-disable @typescript-eslint/naming-convention */
import { apiFetch } from '.';

type PaymentGateway = {
  ds_merchant_parameters: string;
  ds_merchant_signature: string;
  signature_version: string;
  redsys_url: string;
};

type PaymentType = {
  date: string;
  email: string;
  name: string;
  course_id: string;
  course_name: string;
  status: string;
  amount: string;
};

type PaymentList = PaymentType[];

const getPaymentGateway = async (courseId: string, previousUrl: string) => {
  const response = await apiFetch('GET', `/api/v1/payments/tpv_create/${courseId}`, { previousUrl });
  return (await response.json()) as HTTPResponseResult<PaymentGateway>;
};

const sendPaymentGatewayResponse = async (merchantParameters: string, signature: string) => {
  const response = await apiFetch(
    'GET',
    `/api/v1/payments/tpv_response?Ds_MerchantParameters=${merchantParameters}&Ds_Signature=${signature}`,
  );
  return (await response.json()) as HTTPResponseResult<string>;
};

const getLastFiftyPayments = async () => {
  const response = await apiFetch('GET', `/api/v1/payment/dQw4w9WgXcQ`, { withAuth: false });
  return (await response.json()) as HTTPResponseResult<PaymentList>;
};

export type { PaymentGateway, PaymentList, PaymentType };
export { getPaymentGateway, sendPaymentGatewayResponse, getLastFiftyPayments };
