import { AnimatePresence } from 'framer-motion';
import { useState, useRef } from 'react';
import type { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getNavOptions, isNavItemActive } from '../../../../utils/helpers/navbar';
import { useClickOutside } from '../../../../utils/hooks/useClickOutside';
import NavMenuItem from '../../MenuItem';

import CoursesDropDown from './CoursesDropDown';
import ToolsDropdown from './ToolsDropdown';
import { StyledNavMenu, StyledNavMenuInner } from './styles';

type DropdownVisible = 'courses' | 'tools' | null;

type DropDownProps = {
  closeMenu: () => void;
  reference: RefObject<HTMLDivElement>;
};

const NavMenu = () => {
  const { t } = useTranslation('translation');
  const nav = useNavigate();
  const [courseMenuVisible, setCourseMenuVisible] = useState<DropdownVisible>(null);
  const options = getNavOptions(nav, setCourseMenuVisible);
  const reference = useRef<HTMLDivElement>(null);
  const launcherReference = useRef<HTMLUListElement>(null);

  const closeDropdown = () => {
    setCourseMenuVisible(null);
  };

  useClickOutside(reference, launcherReference, closeDropdown);

  const dropdowns = {
    courses: <CoursesDropDown closeMenu={closeDropdown} reference={reference} />,

    tools: <ToolsDropdown closeMenu={closeDropdown} reference={reference} />,
  };

  return (
    <>
      <StyledNavMenu>
        <StyledNavMenuInner ref={launcherReference}>
          {options.map((option) => (
            <NavMenuItem
              action={option.action}
              isActive={isNavItemActive(option.title)}
              key={option.title}
              title={t(option.title)}
            />
          ))}
        </StyledNavMenuInner>
      </StyledNavMenu>
      <AnimatePresence>{Boolean(courseMenuVisible) && dropdowns[courseMenuVisible!]}</AnimatePresence>
    </>
  );
};

export type { DropdownVisible, DropDownProps };
export default NavMenu;
