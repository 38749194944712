import { useTranslation } from 'react-i18next';

import Title from '../../components/ui/Title';
import ContactForm from '../Forms/Contact';

import ContactInfo from './Info';
import { StyledContactPage, StyledContactContent } from './styles';

const ContactPage = () => {
  const { t } = useTranslation('translation');
  return (
    <StyledContactPage>
      <Title content={t('contact.title')} variant={2} />
      <StyledContactContent>
        <ContactInfo />
        <ContactForm />
      </StyledContactContent>
    </StyledContactPage>
  );
};

export default ContactPage;
