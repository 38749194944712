import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledTitle, StyledCourseGroup, StyledCourseCol } from '../styles';
import type { CourseHeadersType } from '../../../../../../utils/api/layout';
import HeaderCourses from '../HeaderCourses';

import InteractiveCourse from './InteractiveCourse';

type HeaderListProps = {
  headerList: CourseHeadersType[];
  closeMenu: () => void;
};

interface CourseGroupProps {
  title: string;
  closeMenu: () => void;
  courses: {
    title: string;
    id: number;
  }[];
}

const CourseGroup = ({ title, closeMenu, courses }: CourseGroupProps) => {
  const { t } = useTranslation('translation');
  return (
    <StyledCourseGroup>
      <StyledTitle>{t(`layout.tabs.${title}`)}</StyledTitle>
      <HeaderCourses closeMenu={closeMenu} headerCourses={courses} />
    </StyledCourseGroup>
  );
};

const HeaderList = ({ headerList, closeMenu }: HeaderListProps) => {
  const [firstItem, ...items] = headerList;
  return (
    <>
      <StyledCourseCol>
        <CourseGroup closeMenu={closeMenu} courses={firstItem.list} title={firstItem.header} />
      </StyledCourseCol>
      <StyledCourseCol>
        {items.map((item) => (
          <CourseGroup closeMenu={closeMenu} courses={item.list} key={item.header} title={item.header} />
        ))}
        <InteractiveCourse closeMenu={closeMenu} />
      </StyledCourseCol>
    </>
  );
};

export default HeaderList;
