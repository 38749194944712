/* eslint-disable max-statements */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import MoreInformation from '../../../components/ui/MoreInformation';
import { getPaymentGateway } from '../../../utils/api/payment';
import type { PaymentGateway } from '../../../utils/api/payment';
import LoadingScreen from '../../../components/ui/LoadingScreen';

import {
  StyledTitle,
  StyledSubtitle,
  StyledText,
  StyledBenefits,
  StyledSection,
  Checkmark,
  StyledIframe,
  StyledError,
} from './styles';

type CourseMoreInfoProps = {
  org: string;
  prices: string[];
  benefits: string[];
  isDownloadable: boolean;
  isPurchasable: boolean;
  scrollToEditions: () => void;
  hasEditions: boolean;
};

const CourseMoreInfo = ({
  org,
  prices,
  benefits,
  isDownloadable,
  isPurchasable,
  scrollToEditions,
  hasEditions = false,
}: CourseMoreInfoProps) => {
  const { t } = useTranslation('translation');
  const { id = '' } = useParams();
  const redsysFormReference = useRef<HTMLFormElement>(null);
  const [paymentGateway, setPaymentGateway] = useState<PaymentGateway>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const redirectToPaymentGateway = () => {
    const fetchGatewayData = async () => {
      const { data, errors } = await getPaymentGateway(id, `?previousUrl=${window.location.pathname}`);
      if (errors) setError(JSON.stringify(errors));
      setPaymentGateway(data);
      setLoading(false);
    };
    if (id.length > 0) {
      setLoading(true);
      void fetchGatewayData();
    }
  };

  const buttonAction = hasEditions ? scrollToEditions : redirectToPaymentGateway;

  useEffect(() => {
    if (paymentGateway) redsysFormReference.current?.submit();
  }, [paymentGateway]);

  if (loading) return <LoadingScreen />;

  return (
    <>
      <MoreInformation
        blueButton={t(`courses.view.${hasEditions ? 'goToEditions' : 'buyCourse'}`)}
        blueButtonFunction={buttonAction}
        isDownloadable={isDownloadable}
        isPurchasable={isPurchasable}
      >
        <StyledIframe title="payment-gateway">
          <form action={paymentGateway?.redsys_url} method="POST" name="from" ref={redsysFormReference}>
            <input name="Ds_SignatureVersion" type="hidden" value={paymentGateway?.signature_version} />
            <input name="Ds_MerchantParameters" type="hidden" value={paymentGateway?.ds_merchant_parameters} />
            <input name="Ds_Signature" type="hidden" value={paymentGateway?.ds_merchant_signature} />
          </form>
        </StyledIframe>
        <StyledTitle>{t('courses.view.moreInfo.title')}</StyledTitle>
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition  */}
        {org !== null && (
          <StyledSection>
            <StyledSubtitle>{t('courses.view.moreInfo.org')}</StyledSubtitle>
            <StyledText>{org}</StyledText>
          </StyledSection>
        )}
        <StyledSection>
          <StyledSubtitle>{t('courses.view.moreInfo.prices')}</StyledSubtitle>
          {prices.map((price) => (
            <StyledText key={price}>{price}</StyledText>
          ))}
        </StyledSection>
        <StyledSection>
          {benefits.map((benefit) => (
            <StyledBenefits key={benefit}>
              <Checkmark />
              <StyledText>{benefit}</StyledText>
            </StyledBenefits>
          ))}
        </StyledSection>
      </MoreInformation>
      {Boolean(error) && <StyledError>{error}</StyledError>}
    </>
  );
};

export default CourseMoreInfo;
