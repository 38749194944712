import { useSelectableList } from '../../../../utils/hooks/useSelectableList';
import Paragraph from '../../Paragraph';

import { StyledContent } from './styles';

const DescriptionContent = () => {
  const { activeContent } = useSelectableList();
  return (
    <StyledContent>
      <Paragraph content={activeContent.description!} variant={3} />
    </StyledContent>
  );
};

export default DescriptionContent;
