import { apiFetch } from '.';

type CourseHeadersType = {
  header: string;
  list: { title: string; id: number }[];
};

const getCourseHeaders = async () => {
  const response = await apiFetch('GET', `api/v1/courses/courses_headers`, { withAuth: false });
  return (await response.json()) as HTTPResponseResult<CourseHeadersType[]>;
};

export { getCourseHeaders };
export type { CourseHeadersType };
