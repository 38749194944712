import styled from 'styled-components';

type CourseCardProps = {
  flip?: boolean;
};

const StyledCourseCard = styled.article<CourseCardProps>`
  margin: 1rem;
  width: 100%;
  max-width: 400px;
  height: 100%;
  background-color: transparent;
  perspective: 1000px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 1rem 0;
  }

  > * {
    &:first-child {
      transform: ${({ flip = false }) => (flip ? 'rotateY(180deg)' : 'none')};
    }
  }
`;

const StyledInnerCard = styled.div`
  position: relative;
  transition: transform 0.7s;
  transform-style: preserve-3d;
  border-radius: 25px;
  box-shadow: ${({ theme }) => theme.shadows.medium};
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 625px;
  }
`;

export { StyledCourseCard, StyledInnerCard };
