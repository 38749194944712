import styled, { css } from 'styled-components';

interface LabelProps {
  readonly htmlFor: string;
}

export const StyledLabel = styled.label<LabelProps>(
  ({ theme: { colors } }) => css`
    color: ${colors.blue};
    font-family: 'RalewaySemiBold';
    font-size: 15px;
    padding: 10px;
    margin: 0 0 0 20px;
  `,
);

export const StyledInput = styled.input(
  ({ theme: { colors } }) => css`
    background: #ffffff;
    border: 1px solid ${colors.blue};
    border-radius: 10px;
    padding: 18px 12px;
    font-family: 'RalewayLight';
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 20px 0 20px;
  `,
);

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledErrorMessage = styled.p`
  color: red;
  margin: 0;
  font-family: 'RalewaySemiBold';
  text-transform: capitalize;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 12px;
  left: 0px;
`;
