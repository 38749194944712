/* eslint-disable @typescript-eslint/no-misused-promises */
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import Title from '../../../components/ui/Title';
import Divider from '../../../components/ui/Divider';
import Paragraph from '../../../components/ui/Paragraph';
import Form from '../../../components/ui/Form';
import { changePassword } from '../../../utils/api/auth';
import { useFetch } from '../../../utils/hooks/useFetch';
import ModalContentContainer from '../../../components/ui/Modal/ModalContentContainer';
import ModalInput from '../../../components/ui/Modal/ModalInput';
import ModalButton from '../../../components/ui/Modal/ModalButton';
import { changePasswordFormSchema } from '../../../utils/schemas/ChangePassword';
import type { IChangePasswordForm } from '../../../utils/schemas/ChangePassword';
import { StyledErrorMessage } from '../../../components/ui/BasicInput/styles';
import Modal from '../../../components/ui/Modal';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';
import Loading, { AnimatedPage as AnimatedContent } from '../../../components/ui/LoadingScreen';

import ChangePasswordSuccess from './Success';

// eslint-disable-next-line max-statements
const ChangePasswordForm = () => {
  const { t } = useTranslation('translation');
  const [searchParameters] = useSearchParams();
  const token = searchParameters.get('token')!;
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IChangePasswordForm>({
    resolver: yupResolver(changePasswordFormSchema),
  });
  const reference = useRef<HTMLDivElement>(null);
  const { hidden, setHidden } = useClickOutside<HTMLDivElement>(reference);

  const { error, success, doFetch, loading } = useFetch<string, IChangePasswordForm>(changePassword);

  useEffect(() => {
    if (success) {
      setHidden(false);
    }
  }, [success, setHidden]);

  const submitHandler = () => {
    void doFetch({
      token,
      password: getValues('password'),
      passwordConfirmation: getValues('passwordConfirmation'),
    });
  };

  if (loading) return <Loading />;
  return (
    <AnimatedContent>
      <ModalContentContainer>
        <Title content={t('forms.changePassword.title')} variant={2} />
        <Divider />
        <Paragraph content={t('forms.changePassword.description')} variant={2} />
        <Form handler={handleSubmit(submitHandler)}>
          <ModalInput
            errors={errors}
            labelText={t('forms.changePassword.password')}
            name="password"
            registerProps={register('password')}
            type="password"
          />
          <ModalInput
            errors={errors}
            labelText={t('forms.changePassword.passwordConfirmation')}
            name="passwordConfirmation"
            registerProps={register('passwordConfirmation')}
            type="password"
          />
          <ModalButton color="blue" text={t('forms.changePassword.submit')} type="submit" />
        </Form>
        {Boolean(error) && <StyledErrorMessage>{error}</StyledErrorMessage>}
        <Modal isHidden={hidden} reference={reference} setHidden={setHidden}>
          <ChangePasswordSuccess />
        </Modal>
      </ModalContentContainer>
    </AnimatedContent>
  );
};

export default ChangePasswordForm;
