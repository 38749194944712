import React from 'react';

import { StyledBluebutton, StyledWhitebutton, StyledTransparentbutton, StyledContainer, StyledText } from './styles';

type ButtonProps = {
  text: string;
  onClick?: () => void;
  children?: React.ReactNode;
  isDisabled?: boolean;
  isSmall?: boolean;
  isMedium?: boolean;
  isLarge?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  type?: 'button' | 'reset' | 'submit' | undefined;
  isPreventDefault?: boolean;
};

type ButtonContentProps = {
  text: string;
  children?: React.ReactNode;
};

const handlePDClick = (
  isPreventDefault: boolean,
  event: React.MouseEvent<HTMLButtonElement>,
  handleClick?: () => void,
) => {
  if (isPreventDefault) {
    event.preventDefault();
  }
  handleClick?.();
};

const ButtonContent = ({ text, children }: ButtonContentProps) => (
  <StyledContainer withIcon={Boolean(children)}>
    <StyledText>{text}</StyledText>
    {Boolean(children) && <span>{children}</span>}
  </StyledContainer>
);

const BlueButton = ({
  text,
  onClick,
  children,
  isDisabled = false,
  isSmall = false,
  isMedium = false,
  isLarge = false,
  isPreventDefault = false,
  type = 'button',
}: ButtonProps) => (
  <StyledBluebutton
    disabled={isDisabled}
    large={isLarge}
    medium={isMedium}
    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
      handlePDClick(isPreventDefault, event, onClick);
    }}
    small={isSmall}
    type={type}
  >
    <ButtonContent text={text}>{children}</ButtonContent>
  </StyledBluebutton>
);

const WhiteButton = ({
  text,
  onClick,
  isDisabled = false,
  children,
  isSmall = false,
  isMedium = false,
  isLarge = false,
  isPreventDefault = false,
  type = 'button',
}: ButtonProps) => (
  <StyledWhitebutton
    disabled={isDisabled}
    large={isLarge}
    medium={isMedium}
    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
      handlePDClick(isPreventDefault, event, onClick);
    }}
    small={isSmall}
    type={type}
  >
    <ButtonContent text={text}>{children}</ButtonContent>
  </StyledWhitebutton>
);

const TransparentButton = ({
  text,
  onClick,
  isDisabled = false,
  children,
  isSmall = false,
  isMedium = false,
  isLarge = false,
  isPreventDefault = false,
}: ButtonProps) => (
  <StyledTransparentbutton
    disabled={isDisabled}
    large={isLarge}
    medium={isMedium}
    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
      handlePDClick(isPreventDefault, event, onClick);
    }}
    small={isSmall}
  >
    <ButtonContent text={text}>{children}</ButtonContent>
  </StyledTransparentbutton>
);

export { BlueButton, WhiteButton, TransparentButton };
