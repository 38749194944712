import { useTranslation } from 'react-i18next';

import Paragraph from '../../../components/ui/Paragraph';

import { StyledDescription, StyledTitle } from './styles';

const LegalAgreement = () => {
  const { t } = useTranslation('translation');
  return (
    <StyledDescription>
      <StyledTitle>{t('legals.agreement.title')} </StyledTitle>
      <Paragraph content={t('legals.agreement.description')} variant={1} />
    </StyledDescription>
  );
};

export default LegalAgreement;
