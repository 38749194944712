const assetURL = process.env.REACT_APP_S3_ASSETS_URL ?? '';

const bannersMock = [
  { id: 1, src: `${assetURL}/banners/posgrados-2023.jpg` },
  { id: 2, src: `${assetURL}/banners/patologia-2023.jpg` },
  { id: 3, src: `${assetURL}/banners/neurologia-2023.jpg` },
  { id: 4, src: `${assetURL}/banners/mi-banner.jpg` },
  { id: 5, src: `${assetURL}/banners/experto-es-2023.jpg` },
  { id: 6, src: `${assetURL}/banners/cardiologia-2023.jpg` },
  { id: 7, src: `${assetURL}/banners/avanzado-2023.jpg` },
  { id: 8, src: `${assetURL}/banners/actualizacion-2023.jpg` },
];

export { bannersMock };
