import { useTranslation } from 'react-i18next';

import { StyledAuthBoxTitle } from './styles';

interface AuthBoxTitleProps {
  title: string;
}

const AuthBoxTitle = ({ title }: AuthBoxTitleProps) => {
  const { t } = useTranslation('translation');

  return <StyledAuthBoxTitle content={t(title)} variant={2} />;
};

export default AuthBoxTitle;
