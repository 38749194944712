import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PhoneImage from '../../../assets/images/phone.png';
import CalcImage from '../../../assets/images/calcIcon.png';
import ProtocolImage from '../../../assets/images/protocolIcon.png';
import { redirectToCalculator } from '../../../utils/helpers/tools';

import {
  StyledToolsBanner,
  StyledImageCol,
  StyledImage,
  StyledToolsCol,
  StyledToolRow,
  StyledToolCol,
  StyledToolRowImage,
  StyledRowTitle,
  StyledRowParagraph,
  StyledTitle,
} from './styles';

const ToolsBanner = () => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const handleProtocol = () => {
    navigate('/tools/protocols');
  };

  return (
    <StyledToolsBanner>
      <StyledImageCol>
        <StyledImage src={PhoneImage} />
      </StyledImageCol>
      <StyledToolsCol>
        <StyledTitle content={t('home.tools.title')} variant={1} />
        <StyledToolRow onClick={redirectToCalculator}>
          <StyledToolRowImage src={CalcImage} />
          <StyledToolCol>
            <StyledRowTitle content={t('home.tools.calc.title')} variant={2} />
            <StyledRowParagraph content={t('home.tools.calc.description')} variant={1} />
          </StyledToolCol>
        </StyledToolRow>
        <StyledToolRow onClick={handleProtocol}>
          <StyledToolRowImage src={ProtocolImage} />
          <StyledToolCol>
            <StyledRowTitle content={t('home.tools.protocol.title')} variant={2} />
            <StyledRowParagraph content={t('home.tools.protocol.description')} variant={1} />
          </StyledToolCol>
        </StyledToolRow>
      </StyledToolsCol>
    </StyledToolsBanner>
  );
};

export default ToolsBanner;
