import { motion } from 'framer-motion';
import styled from 'styled-components';

type LoadingProps = {
  fullpage: boolean;
};

const StyledLoadingScreenContainer = styled(motion.div)<LoadingProps>`
  width: 100%;
  height: ${({ fullpage }) => (fullpage ? 'calc(100svh - 200px)' : '100%')};
  position: ${({ fullpage }) => (fullpage ? 'absolute' : 'relative')};
  top: ${({ fullpage }) => (fullpage ? '200px' : 'unset')};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
`;

const StyledLogo = styled.img`
  width: 100px;
  margin-bottom: 1rem;
  opacity: 0.15;
  transform: scale(1);
  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.05;
    }

    70% {
      transform: scale(1);
      opacity: 0.15;
    }

    100% {
      transform: scale(0.95);
      opacity: 0.05;
    }
  }
`;

export { StyledLoadingScreenContainer, StyledLogo };
