import Contact from './Contact';
import Legals from './Legal';
import Utilities from './Utilities';
import { StyledFooter } from './styles';

const Footer = () => (
  <StyledFooter>
    <Contact />
    <Utilities />
    <Legals />
  </StyledFooter>
);

export default Footer;
