import styled from 'styled-components';

import { StyledTitle2 } from '../../Title/styles';
import { StyledParagraph2 } from '../../Paragraph/styles';

const StyledValue = styled.div`
  display: inline-flex;
  > * {
    &:first-child {
      margin-right: 4px;
    }
  }
`;

const StyledInnerCardFront = styled.div`
  width: calc(100% - 84px);
  left: 42px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    position: absolute;
    height: 625px;
  }
`;

const StyledTitleWrapper = styled.div`
  ${StyledTitle2} {
    font-size: 28px;
  }
`;

const StyledDescWrapper = styled.div`
  flex: 1;
  ${StyledParagraph2} {
    font-size: 15px;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px 0;
`;

export { StyledTitleWrapper, StyledDescWrapper, StyledInnerCardFront, StyledValue, StyledButtonWrapper };
