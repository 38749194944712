import styled from 'styled-components';

type MenuItemProps = {
  isActive: boolean;
};

const StyledMenuItem = styled.li<MenuItemProps>`
  font-family: ${({ isActive }) => (isActive ? 'RalewayBold' : 'RalewayLight')};
  font-size: 20px;
  color: ${({ theme }) => theme.colors.blue};
  line-height: 30px;
  width: 150px;
  position: relative;
  text-align: center;
  margin-inline: 0.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin-bottom: 1rem;
  }

  &:before {
    content: '';
    border-bottom: 4px solid ${({ theme }) => theme.colors.blue};
    width: ${({ isActive }) => (isActive ? '150px' : '0')};
    position: absolute;
    bottom: -10px;
    left: 0;
    transition: width 0.35s;
  }

  &:hover {
    font-family: 'RalewayBold';
    cursor: pointer;
    &:before {
      width: 150px;
    }
  }
`;

export { StyledMenuItem };
