import styled from 'styled-components';

const StyledEditionCardsContainer = styled.div`
  margin-top: 90px;
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

export { StyledEditionCardsContainer };
