/* eslint-disable react/jsx-props-no-spreading */
import { ErrorMessage } from '@hookform/error-message';
import type { FieldErrorsImpl, UseFormRegisterReturn } from 'react-hook-form';

import { StyledInput, StyledLabel, StyledInputContainer, StyledErrorMessage } from './styles';

type InputProps = {
  name: string;
  type?: 'email' | 'password' | 'tel' | 'text';
  labelText: string;
  errors?: FieldErrorsImpl;
  registerProps: UseFormRegisterReturn;
};

const Input = ({ name, type = 'text', labelText, errors, registerProps }: InputProps) => (
  <StyledInputContainer>
    <StyledLabel htmlFor={name}>{labelText}</StyledLabel>
    <StyledInput id={name} type={type} {...registerProps} />
    {Boolean(errors) && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <StyledErrorMessage>{message}</StyledErrorMessage>}
      />
    )}
  </StyledInputContainer>
);

export default Input;
