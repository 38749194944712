/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { sendContactEmail } from '../../../utils/api/home';
import contactFormSchema from '../../../utils/schemas/Contact';
import type { IContactForm } from '../../../utils/schemas/Contact';
import ModalInput from '../../../components/ui/Modal/ModalInput';
import ModalButton from '../../../components/ui/Modal/ModalButton';
import Form from '../../../components/ui/Form';
import Title from '../../../components/ui/Title';
import ModalTextField from '../../../components/ui/Modal/ModalTextField';
import SuccessIcon from '../../../assets/images/successIcon.png';

import { StyledFormGroup, StyledContactForm, StyledIcon } from './styles';

const ContactForm = () => {
  const { t } = useTranslation('translation');
  const [submited, setSubmited] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IContactForm>({
    resolver: yupResolver(contactFormSchema),
  });

  const submitHandler = async (values: IContactForm) => {
    const { data } = await sendContactEmail({
      fullname: values.name,
      email: values.email,
      reason: values.reason,
      message: values.message,
    });
    if (data) {
      setSubmited(true);
    }
  };

  if (submited)
    return (
      <StyledContactForm>
        <StyledIcon src={SuccessIcon} />
        <Title content={t('forms.contact.success')} variant={1} />
      </StyledContactForm>
    );

  return (
    <StyledContactForm>
      <Form handler={handleSubmit(submitHandler)}>
        <StyledFormGroup>
          <ModalInput
            errors={errors}
            labelText={t('forms.contact.name')}
            name="name"
            registerProps={register('name')}
          />
          <ModalInput
            errors={errors}
            labelText={t('forms.contact.email')}
            name="email"
            registerProps={register('email')}
          />
        </StyledFormGroup>
        <ModalInput
          errors={errors}
          labelText={t('forms.contact.reason')}
          name="reason"
          registerProps={register('reason')}
        />
        <ModalTextField
          errors={errors}
          labelText={t('forms.contact.message')}
          name="message"
          registerProps={register('message')}
        />
        <ModalButton color="blue" text={t('forms.contact.submit')} type="submit" />
      </Form>
    </StyledContactForm>
  );
};

export default ContactForm;
