import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Divider from '../../../../components/ui/Divider';
import ModalContentContainer from '../../../../components/ui/Modal/ModalContentContainer';
import ModalTitle from '../../../../components/ui/Modal/ModalTitle';
import ModalDescription from '../../../../components/ui/Modal/ModalDescription';
import ModalButton from '../../../../components/ui/Modal/ModalButton';

const SignUpSuccess = () => {
  const { t } = useTranslation('translation');

  const nav = useNavigate();

  const handleLoginClick = () => {
    nav('/login');
  };

  const handleHomeClick = () => {
    nav('/');
  };

  return (
    <ModalContentContainer>
      <ModalTitle content={t('forms.signUp.modalTitle')} />
      <Divider />
      <ModalDescription content={t('forms.signUp.modalDescription')} />
      <ModalButton color="blue" onClick={handleLoginClick} text={t('forms.signUp.login')} />
      <ModalButton color="transparent" onClick={handleHomeClick} text={t('forms.signUp.home')} />
    </ModalContentContainer>
  );
};

export default SignUpSuccess;
