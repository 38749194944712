import type { FieldValues } from 'react-hook-form';
// eslint-disable-next-line import/no-namespace
import * as yup from 'yup';

const signUpFormSchema = yup.object({
  name: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  password: yup.string().required('Required'),

  passwordConfirmation: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password')], 'Passwords does not match'),
});

export interface ISignUpForm extends FieldValues {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export default signUpFormSchema;
