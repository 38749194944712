/* eslint-disable max-statements */
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCourse } from '../../utils/api/course';
import type { CourseType, EditionType } from '../../utils/api/course';
import CourseDetails from '../../components/ui/CourseDescription';
import TabManager from '../../components/ui/TabManager';
import LoadingScreen, { AnimatedPage } from '../../components/ui/LoadingScreen';

import MoreInfo from './MoreInfo';
import {
  StyledCourse,
  StyledLeftside,
  StyledRightside,
  StyledBottomSide,
  StyledTop,
  StyledMiddle,
  StyledBanner,
} from './styles';
import TabContentManager from './TabContentManager';

type CourseTabs = 'content' | 'details' | 'docentTeam' | 'editions';

const tabs = ['content', 'details', 'docentTeam'];
const tabsWithEditions = ['editions', 'content', 'details', 'docentTeam'];

type CourseContent = { label: string; description: string }[];

const CourseDetailPage = () => {
  const [courseInfo, setCourseInfo] = useState<CourseType>();
  const [content, setContent] = useState<CourseContent | EditionType[]>([{ label: '', description: '' }]);
  const [tabList, setTabList] = useState<string[]>(tabs);
  const [activeTab, setActiveTab] = useState<CourseTabs | null>(null);
  const { id = '' } = useParams();
  const bottomSideReference = useRef<HTMLDivElement>(null);

  const scrollToEditions = () => {
    setActiveTab('editions');
    if (bottomSideReference.current)
      bottomSideReference.current.scrollIntoView({
        behavior: 'smooth',
      });
  };

  useEffect(() => {
    const getCourseInfo = async () => {
      const { data } = await getCourse(id);
      if (data) {
        setCourseInfo(data);
        if (data.editions.length === 0) {
          setTabList(tabs);
          setActiveTab('content');
        } else {
          setTabList(tabsWithEditions);
          setActiveTab('editions');
        }
      }
    };
    void getCourseInfo();
  }, [setCourseInfo, id]);

  useEffect(() => {
    if (courseInfo && activeTab) {
      setContent(courseInfo[activeTab]);
    }
  }, [activeTab, courseInfo]);

  if (activeTab === null || courseInfo === undefined) {
    return <LoadingScreen fullpage />;
  }
  return (
    <AnimatedPage>
      <StyledCourse>
        {Boolean(courseInfo.banner) && (
          <StyledTop>
            <StyledBanner alt={`banner ${courseInfo.title}`} src={courseInfo.banner} />
          </StyledTop>
        )}
        <StyledMiddle>
          <StyledLeftside>
            <CourseDetails chips={courseInfo.tags} content={courseInfo.description} title={courseInfo.title} />
          </StyledLeftside>
          <StyledRightside>
            <MoreInfo
              benefits={courseInfo.benefits}
              hasEditions={courseInfo.editions.length > 0}
              isDownloadable={courseInfo.downloadable}
              isPurchasable={courseInfo.purchasable}
              org={courseInfo.organization}
              prices={courseInfo.values}
              scrollToEditions={scrollToEditions}
            />
          </StyledRightside>
        </StyledMiddle>
        <StyledBottomSide ref={bottomSideReference}>
          <TabManager
            activeTab={activeTab}
            setActiveTab={(value) => {
              setActiveTab(value as CourseTabs);
            }}
            tabList={tabList}
          >
            <TabContentManager activeTab={activeTab} content={content} />
          </TabManager>
        </StyledBottomSide>
      </StyledCourse>
    </AnimatedPage>
  );
};

export type { CourseContent };
export default CourseDetailPage;
