import { useEffect, useState } from 'react';

import { getLastFiftyPayments } from '../../../utils/api/payment';
import type { PaymentList } from '../../../utils/api/payment';
import LoadingScreen from '../../../components/ui/LoadingScreen';

import PaymentRow from './Row';
import { StyledTable, StyledTableHeaders, StyledTableRow } from './styles';

const PaymentsTable = () => {
  const [paymentList, setPaymentList] = useState<PaymentList>();

  useEffect(() => {
    const getPaymentContent = async () => {
      const { data } = await getLastFiftyPayments();
      if (data) {
        setPaymentList(data);
      }
    };
    void getPaymentContent();
  }, []);

  if (paymentList === undefined) {
    return <LoadingScreen fullpage />;
  }

  return (
    <StyledTable>
      <thead>
        <StyledTableRow>
          <StyledTableHeaders>Fecha</StyledTableHeaders>
          <StyledTableHeaders>Email</StyledTableHeaders>
          <StyledTableHeaders>Nombre</StyledTableHeaders>
          <StyledTableHeaders>Id Curso</StyledTableHeaders>
          <StyledTableHeaders>Nombre Curso</StyledTableHeaders>
          <StyledTableHeaders>Estado</StyledTableHeaders>
          <StyledTableHeaders>Monto</StyledTableHeaders>
        </StyledTableRow>
      </thead>
      <tbody>
        {paymentList.map((payment) => (
          <PaymentRow key={undefined} payment={payment} />
        ))}
      </tbody>
    </StyledTable>
  );
};

export default PaymentsTable;
