import { useState } from 'react';

const useFlip = (initial: boolean) => {
  const [flip, setFlip] = useState(initial);

  const mouseLeave = () => {
    setFlip(false);
  };

  const mouseOver = () => {
    setFlip(true);
  };

  return { flipped: flip, mouseLeave, mouseOver };
};
export { useFlip };
