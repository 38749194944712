import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFlip } from '../../../utils/hooks/useFlip';

import CardBack from './Back';
import CardFront from './Front';
import { StyledCourseCard, StyledInnerCard } from './styles';

type CourseCardProps = {
  title: string;
  id: number;
  tags: string[];
  description: string;
  values: string;
  image: string;
};

const CourseCard = ({ title, description, values, tags, image, id }: CourseCardProps) => {
  const { flipped, mouseLeave, mouseOver } = useFlip(false);
  const nav = useNavigate();

  const handleClick = useCallback(() => {
    nav(`courses/${id}`);
  }, [nav, id]);

  const cardInfo = useMemo(
    () => ({
      title,
      description,
      values,
      tags,
      handleClick,
    }),
    [title, description, values, tags, handleClick],
  );

  return (
    <StyledCourseCard flip={flipped} onMouseLeave={mouseLeave} onMouseOver={mouseOver}>
      <StyledInnerCard>
        <CardFront cardInfo={cardInfo} />
        <CardBack handleClick={handleClick} image={image} />
      </StyledInnerCard>
    </StyledCourseCard>
  );
};

export type { CourseCardProps };
export default CourseCard;
