import React, { createContext, useEffect, useMemo, useState } from 'react';

import { getActiveContent } from '../../../utils/helpers/selectableList';
import CertificationExample from '../../../assets/images/certificationExample.png';

import Options from './Options';
import { StyledSelectableList, StyledContent, StyledImage } from './styles';

type SelectableLinks = {
  title: string;
  url: string;
};

type SelectableListItemProps = {
  label: string;
  description?: string;
  links?: SelectableLinks[];
};

interface SelectableListProps extends React.PropsWithChildren {
  content: SelectableListItemProps[];
  activeTab?: string;
}

interface ISelectableListContext {
  activeContent: SelectableListItemProps;
}

const selectableListContext = createContext<ISelectableListContext | undefined>(undefined);

const SelectableList = ({ content, activeTab, children }: SelectableListProps) => {
  const [activeItem, setActiveItem] = useState(content[0].label);

  useEffect(() => {
    setActiveItem(content[0].label);
  }, [activeTab, content]);

  const activeContentValue = useMemo(
    () => ({
      activeContent: getActiveContent(activeItem, content),
    }),
    [activeItem, content],
  );

  return (
    <StyledSelectableList>
      <Options activeItem={activeItem} content={content} setActiveItem={setActiveItem} />
      <StyledContent>
        <selectableListContext.Provider value={activeContentValue}>{children}</selectableListContext.Provider>
        {/* checkear activeItem if cuando se internacionalice el back */}
        {activeTab === 'details' && activeItem === 'Certificaciones' && (
          <StyledImage alt="Certification example image" src={CertificationExample} />
        )}
      </StyledContent>
    </StyledSelectableList>
  );
};

export type { SelectableListItemProps, SelectableLinks };
export { selectableListContext };
export default SelectableList;
