import type { SelectableListItemProps } from '../../components/ui/SelectableList';

import { apiFetch } from '.';

type ProtocolsType = SelectableListItemProps[];

const getProtocols = async () => {
  const response = await apiFetch('GET', `api/v1/protocols`, { withAuth: false });
  return (await response.json()) as HTTPResponseResult<ProtocolsType>;
};

export { getProtocols };
export type { ProtocolsType };
