import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { StyledAuthBoxContainer, StyledBackLink, StyledBox } from './styles';

const AuthBox = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const handleBackClick = () => {
    const toPreviousPage = -1;
    navigate(toPreviousPage);
  };

  return (
    <StyledAuthBoxContainer>
      <StyledBackLink onClick={handleBackClick}>{t('auth.goBack')}</StyledBackLink>
      <StyledBox>{children}</StyledBox>
    </StyledAuthBoxContainer>
  );
};

export default AuthBox;
