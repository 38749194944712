import { motion, AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';

import Logo from '../../../assets/images/logo-icon.png';
import { animationIn, animationOut } from '../../../utils/helpers/animations';

import { StyledLoadingScreenContainer, StyledLogo } from './styles';

interface LoadingScreenProps {
  fullpage?: boolean;
}

const LoadingScreen = ({ fullpage = false }: LoadingScreenProps) => (
  <StyledLoadingScreenContainer fullpage={fullpage}>
    <StyledLogo alt="Loading Icon" src={Logo} />
  </StyledLoadingScreenContainer>
);

const AnimatedPage = ({ children }: React.PropsWithChildren) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    if ('scrollRestoration' in window.history) {
      // eslint-disable-next-line fp/no-mutation
      window.history.scrollRestoration = 'manual';
    }
  }, []);
  return (
    <AnimatePresence>
      <motion.div animate={animationIn} exit={animationOut} initial={animationOut}>
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export { AnimatedPage };
export default LoadingScreen;
