import { ReactComponent as TwitterIcon } from '../../assets/images/twit.svg';
import { ReactComponent as FacebookIcon } from '../../assets/images/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/insta.svg';
import { ReactComponent as VimeoIcon } from '../../assets/images/vimeo.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/images/linkedin.svg';

const utilities = [
  { label: 'layout.footer.utilities.inatal', url: 'https://inatal.org/' },
  { label: 'layout.footer.utilities.fundation', url: 'https://fetalmedicine.org/' },
  { label: 'layout.footer.utilities.calculator', url: 'https://portal.medicinafetalbarcelona.org/calc/' },
  { label: 'layout.footer.utilities.protocol', url: '/tools/protocols' },
];

const legals = [
  { label: 'layout.footer.legal.agreement', url: '/legalAgreement' },
  { label: 'layout.footer.legal.privacy', url: '/privacyPolicy' },
  { label: 'layout.footer.legal.cookies', url: '/cookies' },
  { label: 'layout.footer.legal.contact', url: '/contact' },
];

const socialMedia = [
  {
    label: 'Twitter',
    icon: TwitterIcon,
    url: '/',
  },
  {
    label: 'Facebook',
    icon: FacebookIcon,
    url: '/',
  },
  {
    label: 'Instagram',
    icon: InstagramIcon,
    url: '/',
  },
  {
    label: 'Vimeo',
    icon: VimeoIcon,
    url: '/',
  },
  {
    label: 'Linkedin',
    icon: LinkedinIcon,
    url: '/',
  },
];

export { utilities, legals, socialMedia };
