import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const StyledCourseContainer = styled.div`
  position: relative;
`;

export const StyledDropDown = styled(motion.div)(
  ({ theme: { colors } }) => css`
    background: ${colors.blue};
    position: inherit;
    display: flex;
    z-index: 1;
    top: 16px;
    left: 0;
    flex-direction: row;
    justify-content: center;
    gap: 3%;
    padding-top: 70px;
    padding-bottom: 70px;
    max-height: 980px;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      top: 0;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      flex-direction: column;
      max-height: fit-content;
      padding-left: 1.5rem;
    }
  `,
);

export const StyledCourseList = styled(motion.div)`
  color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const StyledTitle = styled.p(
  ({ theme: { colors } }) => css`
    width: 300px;
    color: ${colors.lightGray};
    text-transform: capitalize;
    font-family: 'OpenSansSemiBold';

    &:after {
      content: '';
      border-bottom: 2px solid ${colors.lightGray};
      padding-top: 7px;
      display: block;
    }
  `,
);

export const StyledCourseGroup = styled.div`
  margin: 2rem 0;
`;

export const StyledCourseCol = styled.div`
  height: fit-content;
`;
