import type { FieldValues } from 'react-hook-form';
// eslint-disable-next-line import/no-namespace
import * as yup from 'yup';

const downloadFormSchema = yup.object({
  name: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  country: yup.string().required('Required'),
  phoneNumber: yup.string().required('Required').matches(/^\d+$/u, 'Must be only digits'),
});

export interface IDownloadForm extends FieldValues {
  name: string;
  email: string;
  country: string;
  phoneNumber: string;
}
export default downloadFormSchema;
