import styled from 'styled-components';

export const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 120px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    height: 200px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 380px !important;
  }
  @media screen and (min-width: 498px) and (max-width: 830px) {
    height: 250px;
  }
`;

export const StyledLogo = styled.img`
  width: fit-content;
  height: fit-content;
  max-height: 48px;
  align-self: flex-end;
  margin-left: 70px;
  margin-top: 25px;
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 20px;
    align-self: flex-start;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    max-height: 60px;
    margin-left: 100px;
    margin-top: 45px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;
