import type { FieldErrorsImpl, UseFormRegisterReturn } from 'react-hook-form';

import TextField from '../../TextField';

import { StyledModalTextFieldContainer } from './styles';

type TextFieldProps = {
  name: string;
  labelText: string;
  errors?: FieldErrorsImpl;
  registerProps: UseFormRegisterReturn;
};

const ModalTextField = ({ name, labelText, errors, registerProps }: TextFieldProps) => {
  return (
    <StyledModalTextFieldContainer>
      <TextField errors={errors} labelText={labelText} name={name} registerProps={registerProps} />
    </StyledModalTextFieldContainer>
  );
};

export default ModalTextField;
