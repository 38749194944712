import styled from 'styled-components';

const StyledHr = styled.hr`
  width: 100%;
  background: ${({ theme }) => theme.colors.blue};
  z-index: 999;
  margin: 0;
`;

export { StyledHr };
