import { StyledSelectableOptions, StyledOption } from '../styles';
import type { SelectableListItemProps } from '..';

type OptionsProps = {
  content: SelectableListItemProps[];
  setActiveItem: (id: string) => void;
  activeItem: string;
};

const Options = ({ content, setActiveItem, activeItem }: OptionsProps) => {
  const handleClick = (id: string) => () => {
    setActiveItem(id);
  };

  return (
    <StyledSelectableOptions>
      {content.map((option) => (
        <StyledOption isActive={activeItem === option.label} key={option.label} onClick={handleClick(option.label)}>
          {option.label}
        </StyledOption>
      ))}
    </StyledSelectableOptions>
  );
};

export default Options;
