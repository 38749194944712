import { css } from 'styled-components';

import RalewayLight from './Raleway-Light.ttf';
import RalewayRegular from './Raleway-Regular.ttf';
import RalewayMedium from './Raleway-Medium.ttf';
import RalewaySemiBold from './Raleway-SemiBold.ttf';
import RalewayBold from './Raleway-Bold.ttf';

const ralewayFontFace = css`
  @font-face {
    font-family: 'RalewayLight';
    src: local('Raleway'), url(${RalewayLight}) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'RalewayRegular';
    src: local('Raleway'), url(${RalewayRegular}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'RalewayMedium';
    src: local('Raleway'), url(${RalewayMedium}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'RalewaySemiBold';
    src: local('Raleway'), url(${RalewaySemiBold}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'RalewayBold';
    src: local('Raleway'), url(${RalewayBold}) format('truetype');
    font-display: swap;
  }
`;

export default ralewayFontFace;
