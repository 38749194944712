import styled, { css } from 'styled-components';

type LanguageProps = {
  readonly active: boolean;
};

export const StyledLanguageButton = styled.button<LanguageProps>(
  ({ theme: { colors }, active }) => css`
    all: unset;
    font-family: 'RalewayBold';
    text-transform: uppercase;
    color: ${active ? colors.blue : colors.gray};

    &:hover {
      cursor: pointer;
    }
  `,
);
