import styled from 'styled-components';

export const StyledDescription = styled.div`
  width: 38%;
  margin: 7% 0 15% 7%;
`;

export const StyledTitle = styled.h1`
  color: ${({ theme }) => theme.colors.blue};
  font-family: 'RalewayBold';
  font-style: normal;
  font-size: 48px;
  line-height: 130%;
  white-space: pre-line;
`;

export const StyledSubtitle = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  font-family: 'RalewayBold';
  font-style: normal;
  font-size: 25px;
  line-height: 130%;
  white-space: pre-line;
`;

export const StyledBlueP = styled.span`
  font-family: 'OpenSansRegular';
  font-size: 24px;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.blue};
`;

export const StyledP = styled.span`
  font-family: 'OpenSansRegular';
  font-size: 24px;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.darkGray};
`;
