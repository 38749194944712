import { getAuthData } from '../../../utils/services/auth';

interface IAuthState {
  loading: boolean;
  isAuthenticated: boolean;
  accessToken?: string;
  refreshToken?: string;
  error?: string;
  newPasswordSuccessful?: boolean;
  newPasswordFailed?: boolean;
  currentUser?: {
    email: string;
    name: string;
  };
}

const buildInitialStatus = (): IAuthState => {
  const authData = getAuthData();
  const { accessToken, refreshToken } = authData;

  if (!accessToken || !refreshToken) {
    return {
      loading: false,
      newPasswordSuccessful: false,
      newPasswordFailed: false,
      isAuthenticated: false,
    };
  }

  return {
    loading: false,
    newPasswordSuccessful: false,
    newPasswordFailed: false,
    isAuthenticated: true,
    accessToken,
    refreshToken,
  };
};

export type { IAuthState };
export const initialAuthState = buildInitialStatus();
