import { StyledMenuItem } from './styles';

interface MenuItemProps {
  action: () => void;
  title: string;
  isActive?: boolean;
}

const MenuItem = ({ action, title, isActive = false }: MenuItemProps) => (
  <StyledMenuItem isActive={isActive} onClick={action}>
    {title}
  </StyledMenuItem>
);

export default MenuItem;
