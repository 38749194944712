/* eslint-disable @typescript-eslint/naming-convention */

import { useEffect, useState } from 'react';
import type { RefObject } from 'react';

const useClickOutside = <NodeT extends HTMLElement = HTMLElement, LauncherNodeT extends HTMLElement = HTMLElement>(
  reference: RefObject<NodeT>,
  launcher?: RefObject<LauncherNodeT>,
  callback?: () => void,
) => {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        reference.current !== null &&
        !reference.current.contains(event.target as Node) &&
        !(launcher?.current?.contains(event.target as Node) ?? false)
      ) {
        setHidden(true);
        callback?.();
      }
    };
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [setHidden, reference, launcher, callback]);

  return { hidden, setHidden, callback };
};

export { useClickOutside };
