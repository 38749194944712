import { useTranslation } from 'react-i18next';

import BasicTab from '../../basicTab';

type TabListProps = {
  tabNameList: string[];
  activeTab: string;
  setActiveTab: (value: string) => void;
};

const TabList = ({ tabNameList, activeTab, setActiveTab }: TabListProps) => {
  const { t } = useTranslation('translation');
  const tabComponentList = tabNameList.map((tab) => (
    <BasicTab
      isActive={activeTab === tab}
      key={tab}
      onClick={() => {
        setActiveTab(tab);
      }}
      text={t(`layout.tabs.${tab}`)}
    />
  ));

  return <> {tabComponentList} </>;
};

export default TabList;
