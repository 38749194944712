import { useSelectableList } from '../../../../utils/hooks/useSelectableList';
import Link from '../../Link';

import { StyledContent } from './styles';

const LinkContent = () => {
  const { activeContent } = useSelectableList();
  return (
    <StyledContent>
      {activeContent.links?.map((link) => (
        <Link key={link.title} title={link.title} url={`${process.env.REACT_APP_S3_ASSETS_URL ?? ''}${link.url}`} />
      ))}
      {/* https://imgur.com/a/IZcHAdp */}
    </StyledContent>
  );
};

export default LinkContent;
