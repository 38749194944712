import styled from 'styled-components';

const StyledTitle = styled.p`
  font-family: 'RalewayRegular';
  font-size: 24px;
  align-self: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.blue};
`;

const StyledSubtitle = styled.p`
  all: unset;
  font-family: 'OpenSansBold';
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blue};
`;

const StyledText = styled.p`
  all: unset;
  font-family: 'OpenSansRegular';
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blue};
`;

const StyledError = styled.p`
  font-family: 'OpenSansRegular';
  font-size: 20px;
  color: red;
  padding: 1rem;
`;

const StyledBenefits = styled.div`
  display: flex;
`;

const Checkmark = styled.span`
  margin-right: 10px;

  &:before {
    content: '';
    display: inline-block;
    padding: 0 10px 0 0;
    height: 5px;
    max-width: 50px !important;
    border-bottom: 2px solid ${({ theme }) => theme.colors.blue};
    border-left: 2px solid ${({ theme }) => theme.colors.blue};
    transform: rotate(-45deg);
  }
`;

const StyledSection = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
`;

const StyledIframe = styled.iframe`
  display: none;
`;

export { StyledTitle, StyledSubtitle, StyledText, StyledBenefits, StyledSection, Checkmark, StyledIframe, StyledError };
