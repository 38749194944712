import { useTranslation } from 'react-i18next';

import { contactInfo } from '../../../../utils/helpers/contact';
import SocialMedia from '../Social';
import { StyledCol } from '../styles';

import { StyledTitle, StyledAddress, StyledPhone, StyledEmail } from './styles';

const Contact = () => {
  const { t } = useTranslation('translation');
  return (
    <StyledCol>
      <StyledTitle>{t('layout.footer.contact.title')}</StyledTitle>
      <StyledAddress>{contactInfo.address}</StyledAddress>
      <StyledPhone>{contactInfo.phone}</StyledPhone>
      <StyledEmail>{contactInfo.email}</StyledEmail>
      <SocialMedia />
    </StyledCol>
  );
};

export default Contact;
