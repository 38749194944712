import styled from 'styled-components';

export const StyledContactPage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const StyledContactContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  width: 80%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`;
