import type { Dispatch, SetStateAction } from 'react';
import type { NavigateFunction } from 'react-router-dom';

import type { DropdownVisible } from '../../components/ui/Navbar/Menu';

const getNavOptions = (nav: NavigateFunction, setCourseMenuVisible: Dispatch<SetStateAction<DropdownVisible>>) => [
  {
    title: 'layout.nav.menu.home',

    action: () => {
      setCourseMenuVisible(null);
      nav('/');
    },
  },
  {
    title: 'layout.nav.menu.courses',

    action: () => {
      setCourseMenuVisible((previous) => (previous === 'courses' ? null : 'courses'));
    },
  },
  {
    title: 'layout.nav.menu.tools',

    action: () => {
      setCourseMenuVisible((previous) => (previous === 'tools' ? null : 'tools'));
    },
  },
  {
    title: 'layout.nav.menu.contact',

    action: () => {
      setCourseMenuVisible(null);
      nav('/contact');
    },
  },
];

const redirectToVirtualCampus = () => {
  window.location.replace('https://campus.medicinafetalbarcelona.org/login/index.php');
};

const navbarSelectOptions = (logout: () => Promise<void>) => [{ id: 1, label: 'auth.signOut', handleAction: logout }];

const promiseHandleClick = (handleAction: () => Promise<void> | void) => () => {
  void handleAction();
};

const pageNameKey = 3;

const getActiveTabName = () => {
  const path = window.location.href.split('/')[pageNameKey] ?? '';

  if (path.length === 0) return 'home';
  return path;
};

const isNavItemActive = (item: string) => {
  return item.split('.')[pageNameKey] === getActiveTabName();
};

export {
  navbarSelectOptions,
  promiseHandleClick,
  redirectToVirtualCampus,
  getNavOptions,
  getActiveTabName,
  isNavItemActive,
};
