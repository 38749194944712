import type { PropsWithChildren, RefObject } from 'react';

import { StyledContainer, StyledInnerContainer } from './styles';

interface CourseCardContainerProps extends PropsWithChildren {
  reference: RefObject<HTMLDivElement>;
}

const CourseCardContainer = ({ children, reference }: CourseCardContainerProps) => (
  <StyledContainer ref={reference}>
    <StyledInnerContainer>{children}</StyledInnerContainer>
  </StyledContainer>
);

export default CourseCardContainer;
