import styled from 'styled-components';

export const StyledPaymentIcon = styled.img`
  width: 150px;
  height: 150px;
  margin: 20px;
`;
export const StyledPaymentResponse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 50px;
  height: 400px;
`;
