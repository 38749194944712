import { ThemeProvider } from 'styled-components';
import type { PropsWithChildren } from 'react';

import { colors } from './colors';
import { shadows } from './shadows';
import { breakpoints } from './breakpoints';
import { globalStyle as GlobalStyle } from './globalStyle';

const baseTheme = {
  colors: { ...colors },
  shadows: { ...shadows },
  breakpoints: { ...breakpoints },
};

type ThemeType = typeof baseTheme;

const Theme = ({ children }: PropsWithChildren) => (
  <ThemeProvider theme={baseTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);

export type { ThemeType };
export default Theme;
