import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

type SlideProps = {
  isActive: boolean;
};

const StyledCarousel = styled.section`
  position: relative;
  width: 100vw;
  aspect-ratio: 2.75;
`;
const StyledControls = styled.div`
  position: absolute;
  height: 100%;
  width: 95%;
  top: 0;
  left: 2.5%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

const arrow = css`
  border: solid ${({ theme }) => theme.colors.lightBlue};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 20px;
  height: 23px;
  width: 20px;
  opacity: 0.25;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;
const StyledButtonLeft = styled.i`
  ${arrow};
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;
const StyledButtonRight = styled.i`
  ${arrow};
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
`;

const StyledInnerCarousel = styled.div`
  aspect-ratio: 2.75;
  overflow: hidden;
`;

const StyledSlide = styled(motion.img)<SlideProps>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: ${({ isActive = false }) => (isActive ? 'block' : 'none')};
`;

export { StyledCarousel, StyledControls, StyledButtonLeft, StyledButtonRight, StyledInnerCarousel, StyledSlide };
