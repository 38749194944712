import type { CourseTabResponse } from '../api/home';

const tabList = [
  'virtualCourses',
  'internationalFormation',
  'internationalMaster',
  'postgrades',
  'universityExperts',
  'interactive',
];

type SortCoursesProps = {
  data: CourseTabResponse;
  lang: LanguageT;
};

const sortCoursesByLanguage = ({ data, lang }: SortCoursesProps) => {
  const secondLanguageCards = lang === 'es' ? data.en : data.es;
  return [...data[lang], ...secondLanguageCards];
};

const initialCourseTab = {
  es: [],
  en: [],
};

const checkRedirection = (value: string) => {
  if (value === 'interactive') {
    window.open('https://fetalbcninteractivecourse.com/', '_blank');
    return true;
  }
  if (value === 'internationalMaster') {
    window.location.replace('/courses/18');
    return true;
  }
  return false;
};

export { tabList, sortCoursesByLanguage, initialCourseTab, checkRedirection };
