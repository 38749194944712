import { useTranslation } from 'react-i18next';

import { StyledBlueP, StyledDescription, StyledSubtitle, StyledTitle, StyledP } from './styles';

const CookiesPage = () => {
  const { t } = useTranslation('translation');
  return (
    <StyledDescription>
      <StyledTitle>{t('legals.cookies.title')} </StyledTitle>
      <StyledSubtitle> {t('legals.cookies.usage')} </StyledSubtitle>
      <StyledP>{t('legals.cookies.usageContent')} </StyledP>
      <StyledSubtitle> {t('legals.cookies.types')} </StyledSubtitle>

      <StyledBlueP>{t('legals.cookies.technicalCookiesBlue')}</StyledBlueP>
      <StyledP>{t('legals.cookies.technicalCookiesGrey')}</StyledP>

      <StyledBlueP>{t('legals.cookies.personalizationCookiesBlue')}</StyledBlueP>
      <StyledP>{t('legals.cookies.personalizationCookiesGrey')}</StyledP>

      <StyledBlueP>{t('legals.cookies.analisisCookiesBlue')}</StyledBlueP>
      <StyledP>{t('legals.cookies.analisisCookiesGrey')}</StyledP>

      <StyledBlueP>{t('legals.cookies.publicityCookiesBlue')}</StyledBlueP>
      <StyledP>{t('legals.cookies.publicityCookiesGrey')}</StyledP>

      <StyledBlueP>{t('legals.cookies.personalPublicitycookiesBlue')}</StyledBlueP>
      <StyledP>{t('legals.cookies.personalPublicitycookiesGrey')}</StyledP>

      <StyledSubtitle>{t('legals.cookies.theCompanyBlue')}</StyledSubtitle>
      <StyledP>{t('legals.cookies.theCompanyGrey')}</StyledP>

      <StyledSubtitle> {t('legals.cookies.legislation')} </StyledSubtitle>
      <StyledP>{t('legals.cookies.legislationContent')} </StyledP>
    </StyledDescription>
  );
};

export default CookiesPage;
