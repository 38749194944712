import { css } from 'styled-components';

import OpenSansLight from './OpenSans-Light.ttf';
import OpenSansRegular from './OpenSans-Regular.ttf';
import OpenSansMedium from './OpenSans-Medium.ttf';
import OpenSansSemiBold from './OpenSans-SemiBold.ttf';
import OpenSansBold from './OpenSans-Bold.ttf';

// 300 light
// 400 regular
// 500 medium
// 600 semi bold
// 700 bold

const openSansFontFace = css`
  @font-face {
    font-family: 'OpenSansLight';
    src: local('OpenSans'), url(${OpenSansLight}) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'OpenSansRegular';
    src: local('OpenSans'), url(${OpenSansRegular}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'OpenSansMedium';
    src: local('OpenSans'), url(${OpenSansMedium}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'OpenSansSemiBold';
    src: local('OpenSans'), url(${OpenSansSemiBold}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'OpenSansBold';
    src: local('OpenSans'), url(${OpenSansBold}) format('truetype');
    font-display: swap;
  }
`;

export default openSansFontFace;
