import styled from 'styled-components';

const StyledSocialIcon = styled.i`
  width: 36px;
  height: 36px;
  margin: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const StyledSocialMedia = styled.div`
  display: inline-block;
`;

export { StyledSocialMedia, StyledSocialIcon };
