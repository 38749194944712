import { useTranslation } from 'react-i18next';

import AuthBox from '../../../components/ui/AuthBox';
import AuthBoxTabs from '../../../components/ui/AuthBox/Tabs';
import AuthBoxTitle from '../../../components/ui/AuthBox/Title';
import SignUpForm from '../../Forms/SignUp';

const SignUpPage = () => {
  const { t } = useTranslation('translation');

  return (
    <AuthBox>
      <AuthBoxTabs />
      <AuthBoxTitle title={t('auth.signUp')} />
      <SignUpForm />
    </AuthBox>
  );
};

export default SignUpPage;
