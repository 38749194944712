import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DownloadForm from '../../../pages/Forms/Download';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';
import { BlueButton, WhiteButton } from '../basicButton';
import Modal from '../Modal';

import { StyledInfo } from './styles';

type MoreInformationProps = {
  blueButton: string;
  blueButtonFunction: () => void;
  children: React.ReactNode;
  isDownloadable: boolean;
  isPurchasable: boolean;
};

const MoreInformation = ({
  blueButtonFunction,
  children,
  blueButton,
  isPurchasable,
  isDownloadable,
}: MoreInformationProps) => {
  const reference = useRef<HTMLDivElement>(null);
  const { hidden, setHidden } = useClickOutside<HTMLDivElement>(reference);
  const { t } = useTranslation('translation');

  const openModal = () => {
    setHidden(false);
  };

  const closeModal = () => {
    setHidden(true);
  };

  return (
    <>
      <StyledInfo>{children}</StyledInfo>
      <BlueButton isDisabled={!isPurchasable} isLarge onClick={blueButtonFunction} text={blueButton} />
      <WhiteButton isDisabled={!isDownloadable} isLarge onClick={openModal} text={t('courses.view.downloadGuide')} />
      <Modal isHidden={hidden} reference={reference} setHidden={setHidden}>
        <DownloadForm closeOnSuccess={closeModal} />
      </Modal>
    </>
  );
};

export default MoreInformation;
