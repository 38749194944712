import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

type UsernameProps = {
  visible: boolean;
};

const sharedFontStyle = css`
  font-family: 'RalewaySemiBold';
  font-size: 14px;
  text-transform: uppercase;
  line-height: 18px;
`;

const StyledSelectContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
const StyledSelect = styled.div`
  border: none;
  background: none;
  position: relative;
`;
const StyledOption = styled.li`
  width: 100%;
  ${sharedFontStyle};
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBlue};
  }
`;

const StyledUsername = styled.p<UsernameProps>`
  ${sharedFontStyle};
  position: relative;
  padding: 0 2.5rem 0 0.5rem;

  &::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 30px;
    right: -5px;
    top: ${({ visible }) => (visible ? '10px' : '-25px')};
    background: white;
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.colors.blue};
    transform: ${({ visible }) => (visible ? 'rotate(135deg)' : 'rotate(45deg)')};
    top: ${({ visible }) => (visible ? '5px' : '0')};
    right: 0;
  }
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOptions = styled(motion.ul)`
  position: absolute;
  background: white;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  top: 2.5rem;
  width: 100%;
  z-index: 3;
  list-style-type: none;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0.5rem;
`;

export { StyledSelectContainer, StyledSelect, StyledOption, StyledIconWrapper, StyledUsername, StyledOptions };
