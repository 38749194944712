import styled from 'styled-components';

const StyledCourse = styled.div``;

const StyledMiddle = styled.div`
  padding: 25px 150px;
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 50px;
    flex-direction: column;
  }
`;

const StyledTop = styled.div`
  aspect-ratio: 2.75;
  overflow: hidden;
`;

const StyledLeftside = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 150px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-right: 0;
  }
`;

const StyledRightside = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const StyledBottomSide = styled.div`
  background-color: ${({ theme }) => theme.colors.lightBlue};
  width: inherit;
  padding: 200px;
  padding-top: 80px;
`;

const StyledBanner = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export { StyledCourse, StyledLeftside, StyledRightside, StyledBottomSide, StyledTop, StyledMiddle, StyledBanner };
