/* eslint-disable camelcase, @typescript-eslint/naming-convention */

import { apiFetch } from '.';

type LoginData = {
  access_token: string;
  refresh_token: string;
};

type CurrentUserData = {
  email: string;
  name: string;
};

type SignUpProps = {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

type ChangePasswordProps = {
  token: string;
  password: string;
  passwordConfirmation: string;
};

const login = async (email: string, password: string) => {
  const body = { auth: { email, password } };
  const response = await apiFetch('POST', 'auth/login', { body, withAuth: false });
  return (await response.json()) as HTTPResponseResult<LoginData>;
};

const logout = async () => await apiFetch('DELETE', 'auth/logout');

const refresh = async (refreshToken: string) => {
  const body = { auth: { refresh_token: refreshToken } };
  return await apiFetch('PATCH', 'auth/refresh', { body });
};

const currentUser = async () => {
  const response = await apiFetch('GET', 'auth/me');
  return (await response.json()) as HTTPResponseResult<CurrentUserData>;
};

const resetPassword = async (email: string) => {
  const body = { user: { email } };
  const response = await apiFetch('POST', 'auth/email_reset_password', { body, withAuth: false });
  return (await response.json()) as HTTPResponseResult<string>;
};

const signUp = async ({ name, email, password, passwordConfirmation }: SignUpProps) => {
  const body = { user: { name, email, password, password_confirmation: passwordConfirmation } };
  const response = await apiFetch('POST', 'api/v1/users', { body, withAuth: false });
  return (await response.json()) as HTTPResponseResult<string>;
};

const changePassword = async ({ token, password, passwordConfirmation }: ChangePasswordProps) => {
  const body = { user: { token, password, password_confirmation: passwordConfirmation } };
  const response = await apiFetch('PATCH', 'auth/reset_password', { body, withAuth: false });
  return (await response.json()) as HTTPResponseResult<string>;
};

export { login, logout, refresh, currentUser, resetPassword, changePassword, signUp };
