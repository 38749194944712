import styled from 'styled-components';

type OptionProps = {
  isActive: boolean;
};

const StyledSelectableList = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column;
  }
`;
const StyledSelectableOptions = styled.ul`
  width: 30%;
  margin: 1rem;
  list-style-type: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 100%;
    margin: 0;
  }
`;
const StyledOption = styled.li<OptionProps>`
  font-family: 'RalewayBold';
  color: ${({ theme, isActive }) => (isActive ? theme.colors.blue : theme.colors.darkGray)};
  font-size: 20px;
  line-height: 30px;
  width: 100%;
  position: relative;
  margin-inline: 0.5rem;
  margin-bottom: 1rem;

  &:before {
    content: '';
    border-bottom: 4px solid ${({ theme }) => theme.colors.blue};
    position: absolute;
    bottom: -10px;
    left: 0;
    width: ${({ isActive }) => (isActive ? '100%' : 0)};
    transition: width 0.35s;
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.blue};
    &:before {
      width: 100%;
    }
  }
`;
const StyledContent = styled.div`
  width: 70%;
  height: fit-content;
  padding: 0 2.5rem;
  margin: auto;
`;

export const StyledImage = styled.img`
  width: inherit;
  height: fit-content;
  align-self: flex-end;
  margin-left: 100px;
  margin-top: 45px;
`;

export { StyledSelectableList, StyledSelectableOptions, StyledOption, StyledContent };
