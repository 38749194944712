import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { DropDownProps } from '..';
import { dropDelay, dropDown, moveUpwards } from '../../../../../utils/helpers/animations';
import { redirectToCalculator } from '../../../../../utils/helpers/tools';
import { Arrow, StyledCourse } from '../styles';

import { StyledToolsDropdown } from './styles';

const ToolsDropdown = ({ closeMenu, reference }: DropDownProps) => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const handleProtocol = () => {
    navigate('/tools/protocols');
    closeMenu();
  };

  return (
    <StyledToolsDropdown
      animate={dropDown}
      exit={moveUpwards}
      initial={moveUpwards}
      ref={reference}
      transition={dropDelay}
    >
      <StyledCourse onClick={redirectToCalculator}>
        {t('layout.nav.toolsOptions.calculator')}
        <Arrow />
      </StyledCourse>
      <StyledCourse onClick={handleProtocol}>
        {t('layout.nav.toolsOptions.protocols')}
        <Arrow />
      </StyledCourse>
    </StyledToolsDropdown>
  );
};

export default ToolsDropdown;
