import { useTranslation } from 'react-i18next';

import CardCourseChips from '../Chips';
import Title from '../Title';

import { ChipContainer, StyledPresentation, StyledPresentationContent } from './styles';

type CourseDetailsProprs = {
  title: string;
  chips: string[];
  content: string;
};

const CourseDetails = ({ title, chips, content }: CourseDetailsProprs) => {
  const { t } = useTranslation('translation');
  return (
    <>
      <Title content={title} variant={1} />
      <ChipContainer>
        <CardCourseChips tags={chips} />
      </ChipContainer>
      <StyledPresentation>{t('courses.view.presentation')}</StyledPresentation>
      <StyledPresentationContent>{content}</StyledPresentationContent>
    </>
  );
};

export default CourseDetails;
