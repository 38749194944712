/* eslint-disable import/no-namespace */

import type { FieldValues } from 'react-hook-form';
import * as yup from 'yup';

const changePasswordFormSchema = yup.object({
  // El token lo dejo sin required ya que no tiene nada que ver con el user
  token: yup.string(),
  password: yup.string().required('Required'),

  passwordConfirmation: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password')], 'Passwords does not match'),
});

export interface IChangePasswordForm extends FieldValues {
  token: string;
  password: string;
  passwordConfirmation: string;
}

export { changePasswordFormSchema };
