import styled from 'styled-components';

const StyledEditionCardsContainer = styled.div`
  margin-top: 90px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 2em;
`;

export { StyledEditionCardsContainer };
