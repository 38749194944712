import React from 'react';

import { StyledTab } from './styles';

type TabProps = {
  isActive?: boolean;
  onClick: () => void;
  text: string;
};

const BasicTab = ({ isActive = false, text, onClick }: TabProps) => {
  return (
    <StyledTab isActive={isActive} onClick={onClick}>
      {text}
    </StyledTab>
  );
};

export default BasicTab;
