/* eslint-disable @typescript-eslint/no-misused-promises */
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import downloadFormSchema from '../../../utils/schemas/Download';
import type { IDownloadForm } from '../../../utils/schemas/Download';
import ModalTitle from '../../../components/ui/Modal/ModalTitle';
import Divider from '../../../components/ui/Divider';
import ModalInput from '../../../components/ui/Modal/ModalInput';
import ModalButton from '../../../components/ui/Modal/ModalButton';
import Form from '../../../components/ui/Form';
import ModalContentContainer from '../../../components/ui/Modal/ModalContentContainer';
import { postAcademicGuideRequest } from '../../../utils/api/course';

interface DownloadFormProps {
  closeOnSuccess: () => void;
}

const DownloadForm = ({ closeOnSuccess }: DownloadFormProps) => {
  const { t } = useTranslation('translation');
  const { id = '' } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IDownloadForm>({
    resolver: yupResolver(downloadFormSchema),
  });

  /* Cambiar a POST y recibir academicGuide */
  const submitHandler = (values: IDownloadForm) => {
    const getAcademicGuide = async () => {
      const { data } = await postAcademicGuideRequest({
        name: values.name,
        email: values.email,
        country: values.country,
        phoneNumber: values.phoneNumber,
        id,
      });
      if (data) {
        window.open(data.url);
      }
      closeOnSuccess();
    };
    void getAcademicGuide();
  };

  return (
    <ModalContentContainer>
      <ModalTitle content={t('forms.download.title')} />
      <Divider />
      <Form handler={handleSubmit(submitHandler)}>
        <ModalInput errors={errors} labelText={t('forms.download.name')} name="name" registerProps={register('name')} />
        <ModalInput
          errors={errors}
          labelText={t('forms.download.email')}
          name="email"
          registerProps={register('email')}
        />
        <ModalInput
          errors={errors}
          labelText={t('forms.download.country')}
          name="country"
          registerProps={register('country')}
        />
        <ModalInput
          errors={errors}
          labelText={t('forms.download.phoneNumber')}
          name="phoneNumber"
          registerProps={register('phoneNumber')}
        />
        <ModalButton color="blue" text={t('forms.download.download')} type="submit" />
      </Form>
    </ModalContentContainer>
  );
};

export default DownloadForm;
