import jwtDecode from 'jwt-decode';

interface AuthData {
  accessToken: string;
  refreshToken: string;
}

const getAuthData = () => {
  const data = localStorage.getItem('auth') ?? '';

  try {
    return JSON.parse(data) as AuthData;
  } catch {
    return { accessToken: '', refreshToken: '' };
  }
};

const setAuthData = (data: AuthData) => {
  localStorage.setItem('auth', JSON.stringify(data));
};
const removeAuthData = () => {
  localStorage.removeItem('auth');
};

type JwtAuthPayload = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  user_id: number;
  exp: number;
};

const getCurrentUserId = () => {
  const authData = getAuthData();
  if (authData.accessToken.length === 0) {
    removeAuthData();
    window.location.reload();
  }
  const { user_id: userId } = jwtDecode<JwtAuthPayload>(authData.accessToken);
  return userId;
};

const isLoggedIn = getAuthData().accessToken.length > 0;

export type { AuthData };
export { getAuthData, setAuthData, removeAuthData, getCurrentUserId, isLoggedIn };
