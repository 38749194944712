import styled from 'styled-components';

const ChipContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
`;

const StyledPresentation = styled.p`
  color: ${({ theme }) => theme.colors.skyBlue};
  font-family: 'OpenSansBold';
`;

const StyledPresentationContent = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: 'OpenSansBold';
`;

export { ChipContainer, StyledPresentation, StyledPresentationContent };
