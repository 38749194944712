import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

type CloseProps = {
  readonly onClick: (value: boolean) => void;
};

export const StyledModal = styled(motion.div)(
  ({ theme: { colors } }) => css`
    display: block;
    background: ${colors.transparentBlue};
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);

export const StyledContent = styled.span(
  ({ theme: { colors } }) => css`
    background: ${colors.white};
    margin: auto;
    border-radius: 21px;
    display: block;
    width: 600px;
    //min-height: 650px;
  `,
);

export const StyledClose = styled.span<CloseProps>`
  position: absolute;
  right: 30px;
  top: 30px;
  width: 32px;
  height: 32px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    display: block;
    left: 15px;
    content: ' ';
    height: 18px;
    width: 2px;
    background-color: #1c1b1f;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const StyledCloseContainer = styled.div`
  height: 50px;
  position: relative;
`;
