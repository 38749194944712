import { StyledTabManager } from './styles';
import TabList from './TabList';

type TabProps = {
  activeTab: string;
  setActiveTab: (value: string) => void;
  tabList: string[];
  children: React.ReactNode;
};

const TabManager = ({ tabList, children, activeTab, setActiveTab }: TabProps) => {
  return (
    <>
      <StyledTabManager>
        <TabList activeTab={activeTab} setActiveTab={setActiveTab} tabNameList={tabList} />
      </StyledTabManager>
      {children}
    </>
  );
};

export default TabManager;
