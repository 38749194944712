import { StyledTitle1, StyledTitle2 } from './styles';

const Title = ({ className, content, variant }: TypographyProps) => {
  const titles: TypographyIndex = {
    title1: <StyledTitle1 className={className}>{content}</StyledTitle1>,
    title2: <StyledTitle2 className={className}>{content}</StyledTitle2>,
  };

  return titles[`title${variant}`];
};

export default Title;
