import styled from 'styled-components';

import Title from '../../Title';

const StyledAuthBoxTitle = styled(Title)`
  line-height: 36px;
  margin: 20px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.blue};
`;

export { StyledAuthBoxTitle };
