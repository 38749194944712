import type { PaymentType } from '../../../../utils/api/payment';
import { StyledTableData, StyledTableRow } from '../styles';

type PaymentRowProps = { payment: PaymentType };

const PaymentRow = ({ payment }: PaymentRowProps) => {
  return (
    <StyledTableRow>
      <StyledTableData>{payment.date}</StyledTableData>
      <StyledTableData>{payment.email}</StyledTableData>
      <StyledTableData>{payment.name}</StyledTableData>
      <StyledTableData>{payment.course_id}</StyledTableData>
      <StyledTableData>{payment.course_name}</StyledTableData>
      <StyledTableData>{payment.status}</StyledTableData>
      <StyledTableData>{payment.amount}</StyledTableData>
    </StyledTableRow>
  );
};

export default PaymentRow;
