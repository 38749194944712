import styled, { css } from 'styled-components';

import Paragraph from '../Paragraph';
import Title from '../Title';

const sharedStyles = css`
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGray};
`;

const StyledEditionCard = styled.article`
  display: flex;
  flex-direction: column;
  width: 365px;
  padding: 35px;
  height: 495px;
  border-radius: 21px;
  box-shadow: ${({ theme }) => theme.shadows.medium};
  justify-content: center;
  align-items: center;
`;
const StyledTitle = styled(Title)`
  text-align: center;
  margin: 0;
`;
const StyledSubtitle = styled(Title)`
  font-family: 'RalewayRegular';
  font-size: 24px;
  line-height: 36px;
  text-align: center;
`;

const StyledParagraph = styled(Paragraph)`
  ${sharedStyles};
  margin: 0;
  width: 65%;
`;

const StyledLabel = styled(StyledParagraph)`
  margin-top: 1.5rem;
`;

const StyledCaption = styled(Paragraph)`
  ${sharedStyles};
  line-height: 24px;
`;

const StyledActionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export {
  StyledEditionCard,
  StyledActionWrapper,
  StyledTitle,
  StyledSubtitle,
  StyledParagraph,
  StyledCaption,
  StyledLabel,
};
