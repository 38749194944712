import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getPostgrade } from '../../utils/api/postgrade';
import type { PostgradeType } from '../../utils/api/postgrade';
import CourseDetails from '../../components/ui/CourseDescription';
import TabManager from '../../components/ui/TabManager';
import { postgradeTabList } from '../../utils/helpers/postgrade';
import type { PostgradeTabs, PostGradeTabsContents } from '../../utils/helpers/postgrade';
import LoadingScreen, { AnimatedPage } from '../../components/ui/LoadingScreen';

import { StyledPostgrade, StyledLeftside, StyledRightside, StyledContents, StyledBottomSide } from './styles';
import MoreInfo from './MoreInfo';
import TabContentManager from './TabContentManager';

const PostgradeDetailPage = () => {
  const [activeTab, setActiveTab] = useState<PostgradeTabs>('editions');
  const [courseInfo, setCourseInfo] = useState<PostgradeType>();
  const [content, setContent] = useState<PostGradeTabsContents[]>([
    { title: '', date: '', format: '', location: '', url: '' },
  ]);
  const { id } = useParams();

  useEffect(() => {
    const getCourseInfo = async () => {
      const result = await getPostgrade(id!);
      setCourseInfo(result);
    };
    void getCourseInfo();
    if (courseInfo) {
      setContent(courseInfo[activeTab]);
    }
  }, [courseInfo, setCourseInfo, id, activeTab]);

  if (courseInfo === undefined) {
    return <LoadingScreen fullpage />;
  }
  return (
    <AnimatedPage>
      <StyledPostgrade>
        <StyledContents>
          <StyledLeftside>
            <CourseDetails chips={courseInfo.tags} content={courseInfo.description} title={courseInfo.title} />
          </StyledLeftside>
          <StyledRightside>
            <MoreInfo description={courseInfo.moreInfo} />
          </StyledRightside>
        </StyledContents>
        <StyledBottomSide>
          <TabManager
            activeTab={activeTab}
            setActiveTab={(value) => {
              setActiveTab(value as PostgradeTabs);
            }}
            tabList={postgradeTabList}
          >
            <TabContentManager activeTab={activeTab} content={content} />
          </TabManager>
        </StyledBottomSide>
      </StyledPostgrade>
    </AnimatedPage>
  );
};

export default PostgradeDetailPage;
