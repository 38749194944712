import { useTranslation } from 'react-i18next';

import Paragraph from '../../../components/ui/Paragraph';

import { StyledDescription, StyledTitle } from './styles';

const ProtocolsDescription = () => {
  const { t } = useTranslation('translation');
  return (
    <StyledDescription>
      <StyledTitle>{t('protocols.title')} </StyledTitle>
      <Paragraph content={t('protocols.description')} variant={1} />
    </StyledDescription>
  );
};

export default ProtocolsDescription;
