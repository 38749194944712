import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { checkPath } from '../../../../utils/helpers/layout';
import MenuItem from '../../MenuItem';

import { StyledTabs, StyledInnerTabs } from './styles';

const AuthBoxTabs = () => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const handleAction = (path: string) => () => {
    navigate(path);
  };

  return (
    <StyledTabs>
      <StyledInnerTabs>
        <MenuItem action={handleAction('/login')} isActive={checkPath('login')} title={t('auth.signIn')} />
        <MenuItem action={handleAction('/signUp')} isActive={checkPath('signUp')} title={t('auth.signUp')} />
      </StyledInnerTabs>
    </StyledTabs>
  );
};

export default AuthBoxTabs;
