import { useTranslation } from 'react-i18next';

import { TransparentButton } from '../../basicButton';
import Divider from '../../Divider';
import Paragraph from '../../Paragraph';
import Title from '../../Title';
import CardCourseChips from '../../Chips';

import {
  StyledInnerCardFront,
  StyledValue,
  StyledTitleWrapper,
  StyledDescWrapper,
  StyledButtonWrapper,
} from './styles';

type CardFrontProps = {
  cardInfo: {
    title: string;
    tags: string[];
    description: string;
    values: string;
    handleClick: () => void;
  };
};

const CardFront = ({ cardInfo }: CardFrontProps) => {
  const { t } = useTranslation('translation');
  return (
    <StyledInnerCardFront>
      <StyledTitleWrapper>
        <Title content={cardInfo.title} variant={2} />
      </StyledTitleWrapper>
      <CardCourseChips tags={cardInfo.tags} />
      <StyledDescWrapper>
        <Paragraph content={cardInfo.description} variant={2} />
      </StyledDescWrapper>
      <StyledValue>
        <Paragraph content={t('home.cards.value')} isBold variant={2} />
        <Paragraph content={cardInfo.values} variant={2} />
      </StyledValue>
      <Divider />
      <StyledButtonWrapper>
        <TransparentButton onClick={cardInfo.handleClick} text={t('home.cards.button')} />
      </StyledButtonWrapper>
    </StyledInnerCardFront>
  );
};

export default CardFront;
