import styled from 'styled-components';

const StyledTitle = styled.p`
  font-family: 'RalewayRegular';
  font-size: 24px;
  align-self: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.blue};
`;

const StyledSubtitle = styled.p`
  all: unset;
  font-family: 'OpenSansBold';
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blue};
`;

const StyledText = styled.p`
  all: unset;
  font-family: 'OpenSansRegular';
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blue};
`;

const StyledSection = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
`;

export { StyledTitle, StyledSubtitle, StyledText, StyledSection };
