/* eslint-disable id-length */

const animationIn = {
  opacity: 1,
};

const animationOut = {
  opacity: 0,
};

const slideIn = {
  opacity: 1,
};

const slideOut = {
  opacity: 0.55,
};

const dropDown = {
  opacity: 1,
  y: 0,
};

const moveUpwards = {
  opacity: 0.13,
  y: -50,
};

const slideDelay = { x: { duration: 2 }, opacity: { duration: 1 } };
const dropDelay = { y: { duration: 0.4 }, opacity: { duration: 0.35 } };
const fadeDelay = { opacity: { duration: 0.5 } };

export { animationIn, animationOut, slideDelay, slideIn, slideOut, fadeDelay, dropDown, moveUpwards, dropDelay };
