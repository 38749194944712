type PostgradeTabs = 'content' | 'details' | 'editions';
const postgradeTabList = ['editions', 'content', 'details'];

type PostgradeEditions = {
  title: string;
  date: string;
  format: string;
  location: string;
  url: string;
};

type PostgradeContents = {
  label: string;
  description: string;
};

type PostGradeTabsContents = PostgradeContents | PostgradeEditions;

export { postgradeTabList };
export type { PostgradeTabs, PostGradeTabsContents, PostgradeContents, PostgradeEditions };
