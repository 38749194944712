import { apiFetch } from '.';

type EditionType = {
  title: string;
  date: string;
  format: string;
  location: string;
  url: string;
};

type CourseType = {
  title: string;
  id: number;
  tags: string[];
  banner: string;
  description: string;
  organization: string;
  values: string[];
  benefits: string[];
  academicGuide: string;
  downloadable: boolean;
  purchasable: boolean;
  content: {
    label: string;
    description: string;
  }[];
  details: {
    label: string;
    description: string;
  }[];
  docentTeam: {
    label: string;
    description: string;
  }[];
  editions: EditionType[];
};

type AcademicGuideRequest = {
  name: string;
  email: string;
  country: string;
  phoneNumber: string;
  id: string;
};

type AcademicGuideResponse = {
  url: string;
};

const getCourse = async (id: string) => {
  const response = await apiFetch('GET', `api/v1/courses/${id}`, { withAuth: false });
  return (await response.json()) as HTTPResponseResult<CourseType>;
};

const postAcademicGuideRequest = async ({ name, email, country, phoneNumber, id }: AcademicGuideRequest) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
  const body = { data: { name, email, country, phone_number: phoneNumber } };
  const response = await apiFetch('POST', `api/v1/courses/${id}/academic_guide`, { body, withAuth: false });
  return (await response.json()) as HTTPResponseResult<AcademicGuideResponse>;
};

export { getCourse, postAcademicGuideRequest };
export type { CourseType, EditionType };
