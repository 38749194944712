import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import type { Dispatch } from 'react';

import { authReducer } from '../Reducers';
import { loginError, loginPending, loginSuccess, logoutPending, logoutSuccess, setCurrentUser } from '../Actions';
import { initialAuthState } from '../Initials';
import { currentUser, login, logout } from '../../../utils/api/auth';
import { setAuthData, removeAuthData, isLoggedIn } from '../../../utils/services/auth';
import type { IAuthAction } from '../Actions';
import type { IAuthState } from '../Initials';

interface IAuthContext {
  loginHelper: (user: { email: string; password: string }, redirectTo?: string) => Promise<void>;
  logoutHelper: () => Promise<void>;
  state: IAuthState;
  dispatch: Dispatch<IAuthAction>;
}

const authStateContext = createContext<IAuthContext | undefined>(undefined);

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation('translation');
  const [state, dispatch] = useReducer(authReducer, initialAuthState);

  const loginHelper = useCallback(
    async (user: { email: string; password: string }, redirectTo = '/') => {
      try {
        dispatch(loginPending());
        const { data, errors } = await login(user.email, user.password);
        if (data) {
          setAuthData({ accessToken: data.access_token, refreshToken: data.refresh_token });
          dispatch(loginSuccess());
          window.location.replace(redirectTo);
        } else {
          dispatch(loginError(JSON.stringify(errors)));
        }
      } catch {
        dispatch(loginError(t('auth.errors.message')));
      }
    },
    [t],
  );

  const logoutHelper = useCallback(async () => {
    dispatch(logoutPending());
    try {
      await logout();
    } catch (error) {
      console.error(error);
    } finally {
      removeAuthData();
      dispatch(logoutSuccess());
      window.location.replace('/login');
    }
  }, []);

  const authValue = useMemo(
    () => ({ loginHelper, logoutHelper, state, dispatch }),
    [state, loginHelper, logoutHelper, dispatch],
  );

  useEffect(() => {
    const retrieveCurrentUser = async () => {
      const { data } = await currentUser();
      if (data) dispatch(setCurrentUser(data));
    };
    if (isLoggedIn) void retrieveCurrentUser();
  }, []);

  return <authStateContext.Provider value={authValue}>{children}</authStateContext.Provider>;
};

export { AuthProvider, authStateContext };
