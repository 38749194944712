import styled, { css } from 'styled-components';

const StyledNavMenu = styled.div`
  background: white;
  width: 100vw;
  height: fit-content;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const StyledNavMenuInner = styled.ul`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  margin: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin: 1rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 1rem;
    flex-direction: column;
    gap: 1rem;
  }
`;

const StyledCourse = styled.div(
  ({ theme: { colors } }) => css`
    color: ${colors.lightGray};
    background: ${colors.blue};
    text-transform: capitalize;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.6s ease;
    border-radius: 250px;
    font-family: 'OpenSansRegular';
    width: fit-content;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-inline: 10px;

    &:hover {
      background: ${colors.lightGray};
      color: ${colors.blue};
      display: block;
      justify-content: space-between;
      cursor: pointer;
    }
  `,
);

const Arrow = styled.span`
  float: right;
  margin-right: 15px;
  margin-left: 15px;
  &:after {
    content: '';
    display: inline-block;
    padding: 0 10px 0 0;
    height: 10px;
    max-width: 50px !important;
    border-bottom: 2px solid ${({ theme }) => theme.colors.blue};
    border-left: 2px solid ${({ theme }) => theme.colors.blue};
    transform: rotate(225deg);
  }
  &:before {
    content: '';
    display: inline-block;
    margin-right: -11px;
    padding: 1px 1px 1px 1px;
    height: 3px;
    width: 15px;
    /* max-width: 50px !important; */
    border-bottom: 2px solid ${({ theme }) => theme.colors.blue};
    transform: rotate(180deg);
  }
`;

export { StyledNavMenu, StyledNavMenuInner, StyledCourse, Arrow };
