import { useNavigate } from 'react-router-dom';

import Logo from '../../../assets/images/logo.png';

import NavMenu from './Menu';
import { StyledLogo, StyledNav, StyledRow } from './styles';
import NavbarButtons from './Buttons';

const Navbar = () => {
  const nav = useNavigate();
  const handleHomeClick = () => {
    nav('/');
  };

  return (
    <StyledNav>
      <StyledRow>
        <StyledLogo alt="Fetal Medicine logo" onClick={handleHomeClick} src={Logo} />
        <NavbarButtons />
      </StyledRow>
      <NavMenu />
    </StyledNav>
  );
};

export default Navbar;
