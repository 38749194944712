/* eslint-disable @typescript-eslint/no-misused-promises */
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import loginFormSchema from '../../../utils/schemas/Login';
import type { ILoginForm } from '../../../utils/schemas/Login';
import Form from '../../../components/ui/Form';
import Input from '../../../components/ui/BasicInput';
import { BlueButton, TransparentButton } from '../../../components/ui/basicButton';
import Modal from '../../../components/ui/Modal';
import ForgotPasswordForm from '../ForgotPassword';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';
import { useAuth } from '../../../utils/hooks/useAuth';

import { StyledLoginForm, StyledInputContainer, StyledButtonContainer, StyledErrorMessage } from './styles';

const LoginForm = () => {
  const { t } = useTranslation('translation');
  const reference = useRef<HTMLDivElement>(null);
  const { hidden, setHidden } = useClickOutside<HTMLDivElement>(reference);
  const [searchParameters] = useSearchParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>({
    resolver: yupResolver(loginFormSchema),
  });
  const { loginHelper, state } = useAuth();

  const submitHandler = async (values: FieldValues) => {
    const email = values.email as string;
    const password = values.password as string;
    await loginHelper({ email, password }, searchParameters.get('previousUrl') ?? undefined);
  };

  const openModal = () => {
    setHidden(false);
  };

  return (
    <StyledLoginForm>
      {Boolean(state.error) && <StyledErrorMessage>{state.error}</StyledErrorMessage>}
      <Form handler={handleSubmit(submitHandler)}>
        <StyledInputContainer>
          <Input errors={errors} labelText={t('forms.login.email')} name="email" registerProps={register('email')} />
        </StyledInputContainer>
        <StyledInputContainer>
          <Input
            errors={errors}
            labelText={t('forms.login.password')}
            name="password"
            registerProps={register('password')}
            type="password"
          />
        </StyledInputContainer>
        <StyledButtonContainer>
          <BlueButton text={t('forms.login.login')} type="submit" />
        </StyledButtonContainer>
        <StyledButtonContainer>
          <TransparentButton
            isPreventDefault
            onClick={() => {
              openModal();
            }}
            text={t('forms.login.forgotPassword')}
          />
        </StyledButtonContainer>
      </Form>
      <Modal isHidden={hidden} reference={reference} setHidden={setHidden}>
        <ForgotPasswordForm />
      </Modal>
    </StyledLoginForm>
  );
};

export default LoginForm;
