import type { Translations } from '../..';

import home from './home.json';
import layout from './layout.json';
import courses from './courses.json';
import auth from './auth.json';
import forms from './forms.json';
import payment from './payment.json';
import legals from './legals.json';
import protocols from './protocols.json';
import contact from './contact.json';

const translation: Translations = { home, layout, courses, auth, forms, payment, legals, protocols, contact };

export default translation;
