import styled, { css } from 'styled-components';

export const StyledWhiteChip = styled.span(
  ({ theme: { colors } }) => css`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    border-radius: 30px;
    padding: 5px 15px 5px 15px;
    text-transform: uppercase;
    background: white;
    border: solid 1px ${colors.blue};
    color: ${colors.blue};
    font-family: OpenSansSemiBold;
    font-size: 13px;
  `,
);
