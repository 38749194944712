/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

type ButtonProps = {
  readonly small: boolean;
  readonly medium: boolean;
  readonly large: boolean;
  readonly type?: string;
};

type ContainerProps = {
  withIcon?: boolean;
};

const StyledButton = styled.button<ButtonProps>(
  ({ small, medium, large }) => css`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    border-radius: 10px;
    padding: 12px 12px;
    text-transform: uppercase;
    height: ${small || medium ? '40' : '50'}px;
    width: ${small ? '128' : medium ? '162' : large ? '400' : '270'}px;

    &:hover {
      cursor: pointer;
    }
    &:disabled {
      opacity: 0.35;
      cursor: default;
      filter: grayscale(100%);
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      padding: 20px 12px;
      height: ${small || medium ? '44' : '56'}px;
    }
  `,
);

export const StyledContainer = styled.div<ContainerProps>`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  gap: ${({ withIcon = false }) => (withIcon ? '11px' : 0)};
`;

export const StyledText = styled.span`
  font-family: 'RalewaySemiBold';
  font-size: 12px;
  line-height: 12px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const StyledWhitebutton = styled(StyledButton)(
  ({ theme: { colors } }) => css`
    background: white;
    border: solid 1px ${colors.blue};
    color: ${colors.blue};
  `,
);

export const StyledBluebutton = styled(StyledButton)(
  ({ theme: { colors } }) => css`
    background: ${colors.blue};
    border: solid 1px ${colors.blue};
    color: ${colors.lightGray};
  `,
);

export const StyledTransparentbutton = styled(StyledButton)(
  ({ theme: { colors } }) => css`
    background: none;
    border: none;
    color: ${colors.blue};
  `,
);
