import type { CourseCardProps } from '../../../../components/ui/CourseCard';
import CourseCard from '../../../../components/ui/CourseCard';

type CardComponentListProps = {
  cards: CourseCardProps[];
};

const CardComponentList = ({ cards }: CardComponentListProps) => (
  <>
    {cards.map((card) => (
      <CourseCard
        description={card.description}
        id={card.id}
        image={card.image}
        key={card.id}
        tags={card.tags}
        title={card.title}
        values={card.values}
      />
    ))}
  </>
);

export default CardComponentList;
