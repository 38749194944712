import { BlueButton, WhiteButton, TransparentButton } from '../../basicButton';

import { StyledModalButtonContainer } from './styles';

type ButtonProps = {
  text: string;
  onClick?: () => void;
  children?: React.ReactNode;
  isSmall?: boolean;
  isMedium?: boolean;
  isLarge?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  isPreventDefault?: boolean;
  color: 'blue' | 'transparent' | 'white';
};

const ModalButton = ({
  text,
  onClick,
  children,
  isSmall = false,
  isMedium = false,
  isLarge = false,
  isPreventDefault = false,
  type = 'button',
  color,
}: ButtonProps) => {
  const colors = {
    blue: (
      <BlueButton
        isLarge={isLarge}
        isMedium={isMedium}
        isPreventDefault={isPreventDefault}
        isSmall={isSmall}
        onClick={onClick}
        text={text}
        type={type}
      >
        {children}
      </BlueButton>
    ),

    white: (
      <WhiteButton
        isLarge={isLarge}
        isMedium={isMedium}
        isPreventDefault={isPreventDefault}
        isSmall={isSmall}
        onClick={onClick}
        text={text}
        type={type}
      >
        {children}
      </WhiteButton>
    ),

    transparent: (
      <TransparentButton
        isLarge={isLarge}
        isMedium={isMedium}
        isPreventDefault={isPreventDefault}
        isSmall={isSmall}
        onClick={onClick}
        text={text}
        type={type}
      >
        {children}
      </TransparentButton>
    ),
  };
  return <StyledModalButtonContainer>{colors[color]}</StyledModalButtonContainer>;
};

export default ModalButton;
