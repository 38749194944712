/* eslint-disable @typescript-eslint/naming-convention */
import { getAuthData, removeAuthData } from '../services/auth';
import type { AuthData } from '../services/auth';

const BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:3000';

const RESPONSE_STATUS = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
  UNPROCESABLE_ENTITY: 426,
  SERVER_ERROR: 500,
};

interface RequestOptions {
  withAuth?: boolean;
  body?: object;
  previousUrl?: string;
}

const buildHeaders = (withAuth: boolean, tokens: AuthData) => {
  const baseHeaders = { 'Content-Type': 'application/json' };

  if (withAuth && tokens.accessToken) {
    return { ...baseHeaders, Authorization: `Token ${tokens.accessToken}` };
  }

  return baseHeaders;
};

type FetchBaseProps = { body?: object; withAuth: boolean; method: HTTPMethod; path: string };

const fetchBase = async ({ body, withAuth, method, path }: FetchBaseProps) => {
  const headers = buildHeaders(withAuth, getAuthData());
  return await fetch(`${BASE_URL}/${path}`, {
    method,
    mode: 'cors',
    headers,
    body: body ? JSON.stringify(body) : undefined,
  });
};

const apiFetch = async (method: HTTPMethod, path: string, options: RequestOptions = {}) => {
  const { body, withAuth = true, previousUrl = '' } = options;
  try {
    const response = await fetchBase({ body, withAuth, method, path });
    if (String(response.status) === '401') {
      removeAuthData();
      window.location.replace(`/login${previousUrl}`);
      throw new Error('Unauthorized');
    }
    return response;
  } catch (error) {
    console.error(error);
    return await Promise.reject(error);
  }
};

export { BASE_URL, RESPONSE_STATUS, apiFetch };
