import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TabManager from '../../../components/ui/TabManager';
import CourseCardContainer from '../../../components/ui/CourseCard/Container';
import { getActiveCourseCards } from '../../../utils/api/home';
import type { CourseTabType, CourseTabResponse } from '../../../utils/api/home';
import {
  sortCoursesByLanguage,
  tabList,
  initialCourseTab,
  checkRedirection,
} from '../../../utils/helpers/courseTabManager';
import { AnimatedPage as AnimatedContent } from '../../../components/ui/LoadingScreen';

import CardComponentList from './CardComponentList';

const CourseTabManager = () => {
  const [activeTab, setActiveTab] = useState<CourseTabType>(tabList[0] as CourseTabType);
  const [courses, setCourses] = useState<CourseTabResponse>(initialCourseTab);
  const { i18n } = useTranslation('translation');
  const cardsSectionReference = useRef<HTMLDivElement>(null);

  const lang = i18n.language as LanguageT;

  const handleActiveTab = (value: string) => {
    const redirected = checkRedirection(value);
    if (!redirected) setActiveTab(value as CourseTabType);
    if (cardsSectionReference.current)
      cardsSectionReference.current.scrollIntoView({
        behavior: 'smooth',
      });
  };

  useEffect(() => {
    const getCourses = async () => {
      const { data } = await getActiveCourseCards({ activeTab });
      if (data) setCourses(data);
    };
    void getCourses();
  }, [activeTab]);

  return (
    <AnimatedContent>
      <TabManager activeTab={activeTab} setActiveTab={handleActiveTab} tabList={tabList}>
        <CourseCardContainer reference={cardsSectionReference}>
          <CardComponentList cards={sortCoursesByLanguage({ data: courses, lang })} />
        </CourseCardContainer>
      </TabManager>
    </AnimatedContent>
  );
};

export default CourseTabManager;
