import styled, { css } from 'styled-components';

const sharedStyle = css`
  font-family: 'RalewayBold';
  font-style: normal;
`;

export const StyledTitle1 = styled.h1`
  ${sharedStyle};
  font-size: 48px;
  line-height: 130%;
`;
export const StyledTitle2 = styled.h1`
  ${sharedStyle};
  font-size: 30px;
  line-height: 120%;
`;
