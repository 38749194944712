import React from 'react';

import { StyledWhiteChip } from './styles';

type ChipProps = {
  text: string;
};

const BasicChip = ({ text }: ChipProps) => <StyledWhiteChip>{text}</StyledWhiteChip>;

export default BasicChip;
