import BasicChip from '../basicChip';

import { StyledChips } from './styles';

type ChipsProps = {
  tags: string[];
};

const CardCourseChips = ({ tags }: ChipsProps) => (
  <StyledChips>
    {tags.map((tag) => (
      <BasicChip key={tag} text={tag} />
    ))}
  </StyledChips>
);

export default CardCourseChips;
