import styled from 'styled-components';

export const StyledDescription = styled.div`
  width: 38%;
  margin: 7% 0 15% 7%;
`;

export const StyledTitle = styled.h1`
  color: ${({ theme }) => theme.colors.blue};
  font-family: 'RalewayBold';
  font-style: normal;
  font-size: 48px;
  line-height: 130%;
`;

export const StyledSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.blue};
  font-family: 'RalewayRegular';
  font-style: normal;
  font-size: 24px;
  line-height: 130%;
`;
