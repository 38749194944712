/* eslint-disable react/jsx-props-no-spreading */
import { ErrorMessage } from '@hookform/error-message';
import type { FieldErrorsImpl, UseFormRegisterReturn } from 'react-hook-form';

import { StyledTextField, StyledLabel, StyledTextFieldContainer, StyledErrorMessage } from './styles';

type TextFieldProps = {
  name: string;
  labelText: string;
  errors?: FieldErrorsImpl;
  registerProps: UseFormRegisterReturn;
};

const TextField = ({ name, labelText, errors, registerProps }: TextFieldProps) => (
  <StyledTextFieldContainer>
    <StyledLabel htmlFor={name}>{labelText}</StyledLabel>
    <StyledTextField id={name} {...registerProps} />
    {Boolean(errors) && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <StyledErrorMessage>{message}</StyledErrorMessage>}
      />
    )}
  </StyledTextFieldContainer>
);

export default TextField;
