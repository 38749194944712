import { useTranslation } from 'react-i18next';

import Paragraph from '../../../components/ui/Paragraph';

import { StyledDescription, StyledSubtitle, StyledTitle } from './styles';

const PrivacyPolicyPage = () => {
  const { t } = useTranslation('translation');
  return (
    <StyledDescription>
      <StyledTitle> {t('legals.privacyPolicy.title')} </StyledTitle>
      <StyledSubtitle> {t('legals.privacyPolicy.identity')} </StyledSubtitle>
      <Paragraph content={t('legals.privacyPolicy.identityContent')} variant={1} />
      <StyledSubtitle> {t('legals.privacyPolicy.condition')} </StyledSubtitle>
      <Paragraph content={t('legals.privacyPolicy.conditionContent')} variant={1} />
      <StyledSubtitle> {t('legals.privacyPolicy.privacyPolicy')} </StyledSubtitle>
      <Paragraph content={t('legals.privacyPolicy.privacyPolicyContent')} variant={1} />
      <StyledSubtitle> {t('legals.privacyPolicy.publicityPolicy')} </StyledSubtitle>
      <Paragraph content={t('legals.privacyPolicy.publicityPolicyContent')} variant={1} />
    </StyledDescription>
  );
};

export default PrivacyPolicyPage;
