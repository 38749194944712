import styled from 'styled-components';

const StyledLink = styled.a`
  all: unset;
  font-family: 'OpenSansRegular';
  font-size: 18px;
  color: ${({ theme }) => theme.colors.blue};
  padding: 5px 13px 5px 13px;
  width: fit-content;
  border-radius: 242px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.lightLightBlue};
  }
`;

export { StyledLink };
