import styled, { css } from 'styled-components';

type TabProps = {
  readonly isActive: boolean;
};

export const StyledTab = styled.button<TabProps>(
  ({ theme: { colors }, isActive }) => css`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    border-radius: 30px;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: ${isActive ? colors.blue : 'none'};
    border: none;
    color: ${isActive ? colors.lightGray : colors.blue};
    text-transform: capitalize;
    width: inherit;
    font-family: ${isActive ? 'RalewayBold' : 'RalewayMedium'};
    cursor: pointer;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      font-size: 25px;
    }
  `,
);
