import styled from 'styled-components';

import Paragraph from '../../../components/ui/Paragraph';
import Title from '../../../components/ui/Title';

const StyledToolsBanner = styled.section`
  display: flex;
  margin: 88px 0;
`;

const StyledImageCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  background: radial-gradient(50% 50% at 50% 50%, #242f62 20.62%, #000b3d 100%);
  border-radius: 0px 52px 35px 0px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const StyledImage = styled.img`
  height: 90%;
`;

const StyledToolsCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  width: 65%;
  height: fit-content;
  align-self: center;
  margin-left: -80px;
  padding-left: 100px;
  background: white;
  border-radius: 52px 0 0 52px;
  filter: drop-shadow(-27px 25px 20px rgba(0, 0, 0, 0.1));
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0;
    width: 80%;
    margin: auto;
    filter: none;
  }
`;

const StyledToolRow = styled.div`
  display: flex;
  width: calc(100% - 94px);
  margin: auto;
  align-items: center;
  margin: 1rem 0;

  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
  }
`;

const StyledToolCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledToolRowImage = styled.img`
  width: 130px;
  height: 130px;
  margin-right: 40px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0;
    width: 80px;
    height: 80px;
    margin: 1rem;
  }
`;

const StyledRowTitle = styled(Title)`
  margin: 0;
  margin-bottom: 1rem;
`;

const StyledRowParagraph = styled(Paragraph)`
  margin: 0;
`;

const StyledTitle = styled(Title)`
  width: 100%;
  margin: 0;
`;

export {
  StyledToolsBanner,
  StyledImageCol,
  StyledImage,
  StyledToolsCol,
  StyledToolRow,
  StyledToolCol,
  StyledToolRowImage,
  StyledRowTitle,
  StyledRowParagraph,
  StyledTitle,
};
