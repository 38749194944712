/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';

type Query<ResponseT, PayloadT> = (payload: PayloadT) => Promise<HTTPResponseResult<ResponseT>>;

function useFetch<ResponseT, PayloadT>(query: Query<ResponseT, PayloadT>) {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [results, setResults] = useState<ResponseT>();

  const doFetch = async (payload: PayloadT) => {
    setLoading(true);
    try {
      const { data, errors } = await query(payload);
      if (data !== undefined) {
        setResults(data);
        setSuccess(true);
      }
      if (errors !== undefined) {
        setError(String(errors));
      }
    } catch (fetchError) {
      setError(fetchError as string);
    } finally {
      setLoading(false);
    }
  };

  return { loading, success, error, data: results, doFetch };
}

export { useFetch };
