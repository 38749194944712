import styled from 'styled-components';

const StyledImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  object-fit: cover;
  border-radius: 25px;
`;

const StyledInnerCardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 625px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column-reverse;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
  &:hover {
    transform: scale(1.05);
    transition: 0.5s;
  }
`;

export { StyledInnerCardBack, StyledImage, StyledButtonWrapper };
