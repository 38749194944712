import { useTranslation } from 'react-i18next';

import { legals } from '../../../../utils/helpers/footer';
import { StyledAnchor, StyledCol, StyledTitle } from '../styles';

const FooterLegals = () => {
  const { t } = useTranslation('translation');
  return (
    <StyledCol>
      <StyledTitle content={t('layout.footer.legal.title')} variant={1} />
      {legals.map((item) => (
        <StyledAnchor href={item.url} key={item.label}>
          {t(item.label)}
        </StyledAnchor>
      ))}
    </StyledCol>
  );
};

export default FooterLegals;
