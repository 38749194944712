import { useTranslation } from 'react-i18next';

import { utilities } from '../../../../utils/helpers/footer';
import { StyledAnchor, StyledCol, StyledTitle } from '../styles';

const FooterUtilities = () => {
  const { t } = useTranslation('translation');
  return (
    <StyledCol>
      <StyledTitle content={t('layout.footer.utilities.title')} variant={1} />
      {utilities.map((utility) => (
        <StyledAnchor href={utility.url} key={utility.label}>
          {t(utility.label)}
        </StyledAnchor>
      ))}
    </StyledCol>
  );
};

export default FooterUtilities;
