import styled from 'styled-components';

const StyledChips = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 1rem 0;
  gap: 5px;
`;

export { StyledChips };
