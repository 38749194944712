interface IAuthPayload {
  accessToken?: string;
  refreshToken?: string;
  error?: string;
  currentUser?: {
    email: string;
    name: string;
  };
}

interface IAuthAction {
  type: string;
  payload?: IAuthPayload;
}

// Actions

const authActions = {
  loginPending: 'LOGIN_PENDING',
  loginFailed: 'LOGIN_FAILED',
  loginSuccessful: 'LOGIN_SUCCESSFUL',
  logoutPending: 'LOGOUT_PENDING',
  logoutSuccessful: 'LOGOUT_SUCCESSFUL',
  newPasswordSuccessful: 'NEW_PASSWORD_SUCCESSFUL',
  newPasswordFailed: 'NEW_PASSWORD_FAILED',
  setCurrentUser: 'SET_CURRENT_USER',
};

// Action Creators

const loginPending = () => ({
  type: authActions.loginPending,
});

const loginSuccess = () => ({
  type: authActions.loginSuccessful,
});

const loginError = (error: string) => ({
  type: authActions.loginFailed,
  payload: { error },
});

const logoutSuccess = () => ({ type: authActions.logoutSuccessful });

const logoutPending = () => ({ type: authActions.logoutPending });

const setCurrentUser = (user: { email: string; name: string }) => ({
  type: authActions.setCurrentUser,

  payload: {
    currentUser: user,
  },
});

export type { IAuthPayload, IAuthAction };
export { authActions, loginPending, loginSuccess, loginError, logoutSuccess, logoutPending, setCurrentUser };
