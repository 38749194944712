import { StyledModalContentContainer } from './styles';

type ContainerProps = {
  children: React.ReactNode;
};

const ModalContentContainer = ({ children }: ContainerProps) => (
  <StyledModalContentContainer>{children}</StyledModalContentContainer>
);

export default ModalContentContainer;
