import CarouselSlider from '../../components/ui/Carousel';
import { bannersMock } from '../../data/home';

import CourseTabManager from './CourseTabManager';
import ToolsBanner from './Tools';

const HomePage = () => (
  <>
    <CarouselSlider data={bannersMock} />
    <CourseTabManager />
    <ToolsBanner />
  </>
);

export default HomePage;
