/* eslint-disable @typescript-eslint/naming-convention */
import { socialMedia } from '../../../../utils/helpers/footer';

import { StyledSocialMedia, StyledSocialIcon } from './styles';

const SocialMedia = () => (
  <StyledSocialMedia>
    {socialMedia.map(({ label, url, icon: Icon }) => (
      <StyledSocialIcon
        aria-label={label}
        key={label}
        onClick={() => {
          window.location.replace(url);
        }}
      >
        <Icon />
      </StyledSocialIcon>
    ))}
  </StyledSocialMedia>
);

export default SocialMedia;
