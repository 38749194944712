import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from '../../../../assets/images/arrow_forward.svg';
import Placeholder from '../../../../assets/images/cardPlaceholder.png';
import { BlueButton } from '../../basicButton';

import { StyledInnerCardBack, StyledImage, StyledButtonWrapper } from './styles';

type CardBackProps = {
  image: string;
  handleClick: () => void;
};

const CardBack = ({ image, handleClick }: CardBackProps) => {
  const { t } = useTranslation('translation');
  const imageReference = useRef<HTMLImageElement>(null);

  const handleImageFallback = () => {
    if (imageReference.current !== null) {
      // eslint-disable-next-line fp/no-mutation
      imageReference.current.src = Placeholder;
    }
  };

  return (
    <StyledInnerCardBack>
      <StyledImage onError={handleImageFallback} ref={imageReference} src={image} />
      <StyledButtonWrapper>
        <BlueButton isMedium onClick={handleClick} text={t('home.cards.button')}>
          <ArrowIcon />
        </BlueButton>
      </StyledButtonWrapper>
    </StyledInnerCardBack>
  );
};

export default CardBack;
