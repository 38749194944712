import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Arrow, StyledCourse } from '../../styles';

type HeaderCoursesProps = {
  headerCourses: {
    title: string;
    id: number;
  }[];
  closeMenu: () => void;
};

const HeaderCourses = ({ headerCourses, closeMenu }: HeaderCoursesProps) => {
  const nav = useNavigate();
  const handleClick = useCallback(
    (id: number) => () => {
      nav(`courses/${id}`);
      closeMenu();
    },
    [nav, closeMenu],
  );
  return (
    <>
      {headerCourses.map((course) => {
        return (
          <StyledCourse key={course.id} onClick={handleClick(course.id)}>
            {course.title}
            <Arrow />
          </StyledCourse>
        );
      })}
    </>
  );
};
export default HeaderCourses;
