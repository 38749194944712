import { useTranslation } from 'react-i18next';

import { StyledCourse } from '../../../styles';
import { StyledTitle } from '../../styles';

type InteractiveCourseProps = {
  closeMenu: () => void;
};

const InteractiveCourse = ({ closeMenu }: InteractiveCourseProps) => {
  const { t } = useTranslation('translation');
  const handleInteractiveClick = () => {
    window.open('https://fetalbcninteractivecourse.com/', '_blank');
    closeMenu();
  };

  return (
    <div key="interactive">
      <StyledTitle>{t(`layout.tabs.interactive`)}</StyledTitle>
      <StyledCourse onClick={handleInteractiveClick}>{t(`layout.tabs.interactive`)}</StyledCourse>
    </div>
  );
};

export default InteractiveCourse;
