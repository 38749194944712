import styled from 'styled-components';

const StyledSignUpForm = styled.div`
  margin: 10px 73px 20px 73px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledErrorMessage = styled.p`
  color: red;
  margin: 0;
  font-family: 'RalewaySemiBold';
  text-transform: capitalize;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 12px;
  left: 0px;
`;

export { StyledSignUpForm, StyledErrorMessage };
