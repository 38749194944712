import styled, { css } from 'styled-components';

const sharedStyles = css`
  color: ${({ theme }) => theme.colors.lightBlue};
  font-family: 'OpenSansRegular';
`;

const StyledTitle = styled.h1`
  color: ${({ theme }) => theme.colors.lightBlue};
  font-family: 'RalewaySemiBold';
  font-size: 35px;
  line-height: 128%;
  max-width: 292px;
  letter-spacing: -0.011em;
`;

const StyledAddress = styled.h2`
  ${sharedStyles};
  font-size: 1.25rem;
  max-width: 425px;
`;

const StyledPhone = styled.h4`
  ${sharedStyles};
  font-size: 1rem;
`;

const StyledEmail = styled.h5`
  ${sharedStyles};
  font-size: 1rem;
`;

export { StyledTitle, StyledAddress, StyledPhone, StyledEmail };
