import { StyledParagraph1, StyledParagraph2, StyledParagraph3 } from './styles';

interface TextProps extends TypographyProps {
  isBold?: boolean;
}

const Paragraph = ({ className, content, isBold = false, variant }: TextProps) => {
  const paragraph: TypographyIndex = {
    paragraph1: (
      <StyledParagraph1 className={className} isBold={isBold}>
        {content}
      </StyledParagraph1>
    ),

    paragraph2: (
      <StyledParagraph2 className={className} isBold={isBold}>
        {content}
      </StyledParagraph2>
    ),

    paragraph3: <StyledParagraph3 className={className}>{content}</StyledParagraph3>,
  };

  return paragraph[`paragraph${variant}`];
};

export default Paragraph;
