import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import type { RefObject } from 'react';

import { animationIn, animationOut } from '../../../utils/helpers/animations';

import { StyledClose, StyledCloseContainer, StyledContent, StyledModal } from './styles';

type ModalProps = {
  children: React.ReactNode;
  isHidden: boolean;
  setHidden: (hidden: boolean) => void;
  reference: RefObject<HTMLDivElement>;
};

const Modal = ({ children, isHidden = false, setHidden, reference }: ModalProps) => {
  useEffect(() => {
    // eslint-disable-next-line fp/no-mutation
    document.body.style.overflow = isHidden ? 'visible' : 'hidden';
  }, [isHidden]);

  return (
    <AnimatePresence>
      {Boolean(!isHidden) && (
        <StyledModal animate={animationIn} data-testid="modal" exit={animationOut} initial={animationOut} key="modal">
          <StyledContent ref={reference}>
            <StyledCloseContainer>
              <StyledClose
                onClick={() => {
                  setHidden(true);
                }}
              />
            </StyledCloseContainer>
            {children}
          </StyledContent>
        </StyledModal>
      )}
    </AnimatePresence>
  );
};

export default Modal;
