import { StyledLanguageButton } from './styles';

type LanguageButtonProps = {
  isActive: boolean;
  children: React.ReactNode;
  onClick: () => void;
};

const LanguageButton = ({ isActive, children, onClick }: LanguageButtonProps) => (
  <StyledLanguageButton active={isActive} onClick={onClick}>
    {children}
  </StyledLanguageButton>
);

export default LanguageButton;
