import { useTranslation } from 'react-i18next';

import AuthBox from '../../../components/ui/AuthBox';
import AuthBoxTabs from '../../../components/ui/AuthBox/Tabs';
import AuthBoxTitle from '../../../components/ui/AuthBox/Title';
import LoginForm from '../../Forms/Login';

const LoginPage = () => {
  const { t } = useTranslation('translation');

  return (
    <AuthBox>
      <AuthBoxTabs />
      <AuthBoxTitle title={t('auth.signIn')} />
      <LoginForm />
    </AuthBox>
  );
};

export default LoginPage;
