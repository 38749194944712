import styled from 'styled-components';

const StyledLayout = styled.main`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledPageContent = styled.section`
  flex: 1;
`;

export { StyledLayout, StyledPageContent };
