import styled from 'styled-components';

const StyledBottomSide = styled.div`
  background-color: ${({ theme }) => theme.colors.lightBlue};
  width: inherit;
  padding: 200px;
  padding-top: 80px;
`;

export { StyledBottomSide };
