import styled from 'styled-components';

type OptionsT = {
  isBold: boolean;
};

const StyledParagraph1 = styled.p<OptionsT>`
  font-family: ${({ isBold }) => (isBold ? 'OpenSansBold' : 'OpenSansRegular')};
  font-size: 18px;
  white-space: pre-line;
`;

const StyledParagraph2 = styled.p<OptionsT>`
  font-family: ${({ isBold }) => (isBold ? 'OpenSansBold' : 'OpenSansRegular')};
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-line;
`;

const StyledParagraph3 = styled.p`
  font-family: 'OpenSansRegular';
  font-size: 20px;
  white-space: pre-line;
`;

export { StyledParagraph1, StyledParagraph2, StyledParagraph3 };
