import styled from 'styled-components';

const StyledContent = styled.div`
  width: 70%;
  height: fit-content;
  padding: 0 2.5rem;
  margin: auto;
`;

export { StyledContent };
