import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Divider from '../../../../components/ui/Divider';
import ModalButton from '../../../../components/ui/Modal/ModalButton';
import ModalContentContainer from '../../../../components/ui/Modal/ModalContentContainer';
import ModalDescription from '../../../../components/ui/Modal/ModalDescription';
import ModalTitle from '../../../../components/ui/Modal/ModalTitle';

type ForgotPasswordErrorProps = {
  error: string;
};

const ForgotPasswordError = ({ error }: ForgotPasswordErrorProps) => {
  const { t } = useTranslation('translation');
  const nav = useNavigate();

  const handleClick = () => {
    nav('/');
  };
  return (
    <ModalContentContainer>
      <ModalTitle content={t('forms.forgotPassword.error.title')} />
      <Divider />
      <ModalDescription content={error} />
      <ModalButton color="blue" onClick={handleClick} text={t('forms.forgotPassword.error.button')} type="button" />
    </ModalContentContainer>
  );
};

export default ForgotPasswordError;
