/* eslint-disable @typescript-eslint/no-misused-promises */
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { forgotPasswordFormSchema } from '../../../utils/schemas/ForgotPassword';
import type { IForgotPasswordForm } from '../../../utils/schemas/ForgotPassword';
import Title from '../../../components/ui/Title';
import Divider from '../../../components/ui/Divider';
import Paragraph from '../../../components/ui/Paragraph';
import Form from '../../../components/ui/Form';
import { resetPassword } from '../../../utils/api/auth';
import { useFetch } from '../../../utils/hooks/useFetch';
import ModalContentContainer from '../../../components/ui/Modal/ModalContentContainer';
import ModalInput from '../../../components/ui/Modal/ModalInput';
import ModalButton from '../../../components/ui/Modal/ModalButton';

import ForgotPasswordError from './Error';
import ForgotPasswordSuccess from './Success';

const ForgotPasswordForm = () => {
  const { t } = useTranslation('translation');
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IForgotPasswordForm>({
    resolver: yupResolver(forgotPasswordFormSchema),
  });

  const { error, success, doFetch } = useFetch<string, string>(resetPassword);

  const submitHandler = () => {
    void doFetch(getValues('email'));
  };

  if (error.length > 0) return <ForgotPasswordError error={error} />;
  if (success) return <ForgotPasswordSuccess />;

  return (
    <ModalContentContainer>
      <Title content={t('forms.forgotPassword.title')} variant={2} />
      <Divider />
      <Paragraph content={t('forms.forgotPassword.description')} variant={2} />
      <Form handler={handleSubmit(submitHandler)}>
        <ModalInput
          errors={errors}
          labelText={t('forms.forgotPassword.email')}
          name="email"
          registerProps={register('email')}
        />
        <ModalButton color="blue" text={t('forms.forgotPassword.submit')} type="submit" />
      </Form>
    </ModalContentContainer>
  );
};

export default ForgotPasswordForm;
