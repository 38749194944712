import styled from 'styled-components';

const StyledAuthBoxContainer = styled.section`
  width: 100vw;
  min-height: calc(100vh - 200px);
  background: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const StyledBackLink = styled.p`
  font-family: 'RalewaySemiBold';
  font-size: 14px;
  color: #000b3d;
  text-transform: uppercase;
  padding-left: 1.5rem;
  margin: 2rem 0;
  position: relative;
  width: 500px;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-bottom: 3px solid ${({ theme }) => theme.colors.blue};
    border-left: 3px solid ${({ theme }) => theme.colors.blue};
    transform: rotate(45deg);
    position: absolute;
    left: 0;
    top: 2px;
  }
  &::after {
    content: '';
    width: 15px;
    height: 8px;
    border-bottom: 3px solid #000b3d;
    position: absolute;
    left: 0;
    top: -1px;
  }
  &:hover {
    font-family: 'RalewayBold';
    cursor: pointer;
  }
`;
const StyledBox = styled.div`
  width: 600px;
  height: fit-content;
  background: white;
  box-shadow: ${({ theme }) => theme.shadows.medium};
  border-radius: 21px;
`;

export { StyledAuthBoxContainer, StyledBackLink, StyledBox };
