import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import { redirectToVirtualCampus, navbarSelectOptions } from '../../../../utils/helpers/navbar';
import { BlueButton, WhiteButton } from '../../basicButton';
import Select from '../../Select';
import { useAuth } from '../../../../utils/hooks/useAuth';
import { ReactComponent as UserIcon } from '../../../../assets/images/account_circle.svg';

import LanguageButton from './LanguageButton';
import { StyledButtonContainer, StyledLanguageButtonContainer } from './styles';

const AuthActions = () => {
  const { t } = useTranslation('translation');
  const {
    state: { isAuthenticated, currentUser },
    logoutHelper,
  } = useAuth();

  const nav = useNavigate();

  const handleLoginClick = () => {
    nav('/login');
  };

  if (!isAuthenticated)
    return <WhiteButton isMedium={!isMobile} isSmall={isMobile} onClick={handleLoginClick} text={t('auth.signIn')} />;
  if (!currentUser) return null;
  return (
    <Select defaultValue={currentUser.name} options={navbarSelectOptions(logoutHelper)}>
      <UserIcon />
    </Select>
  );
};

const NavbarButtons = () => {
  const { t, i18n } = useTranslation('translation');

  const isActive = (lang: LanguageT) => i18n.language === lang;
  const handleChangeLanguage = (lang: LanguageT) => () => {
    void i18n.changeLanguage(lang as string);
  };
  return (
    <StyledButtonContainer>
      <BlueButton
        isMedium={!isMobile}
        isSmall={isMobile}
        onClick={redirectToVirtualCampus}
        text={t('layout.nav.virtualCampus')}
      />
      <AuthActions />
      <StyledLanguageButtonContainer>
        <LanguageButton isActive={isActive('en')} onClick={handleChangeLanguage('en')}>
          en
        </LanguageButton>
        <LanguageButton isActive={isActive('es')} onClick={handleChangeLanguage('es')}>
          es
        </LanguageButton>
      </StyledLanguageButtonContainer>
    </StyledButtonContainer>
  );
};

export default NavbarButtons;
