import styled from 'styled-components';

export const StyledTabManager = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: stretch;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`;
