import type { CourseCardProps } from '../../components/ui/CourseCard';

import { apiFetch } from '.';

const endpointResolver = {
  virtualCourses: 'virtual_courses',
  postgrades: 'postgrade_courses',
  interactiveCourse: 'interactive_courses',
  internationalFormation: 'international_formation_courses',
  universityExperts: 'university_experts_courses',
  internationalMaster: 'international_master_courses',
};

type CourseTabType =
  | 'interactiveCourse'
  | 'internationalFormation'
  | 'internationalMaster'
  | 'postgrades'
  | 'universityExperts'
  | 'virtualCourses';

type GetActiveCardsProps = {
  activeTab: CourseTabType;
};

type ContactProps = {
  fullname: string;
  email: string;
  reason: string;
  message: string;
};

type CourseTabResponse = {
  es: CourseCardProps[];
  en: CourseCardProps[];
};

const getActiveCourseCards = async ({ activeTab }: GetActiveCardsProps) => {
  const response = await apiFetch('GET', `api/v1/courses/${endpointResolver[activeTab]}`, { withAuth: false });
  return (await response.json()) as HTTPResponseResult<CourseTabResponse>;
};

const sendContactEmail = async (contactData: ContactProps) => {
  const body = { data: contactData };
  const response = await apiFetch('POST', 'api/v1/users/contact', { body, withAuth: false });
  return (await response.json()) as HTTPResponseResult<{ status: string }>;
};

export type { CourseTabType, GetActiveCardsProps, CourseTabResponse };
export { getActiveCourseCards, sendContactEmail };
