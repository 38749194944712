import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';

import { slideDelay } from '../../../utils/helpers/animations';
import { useSlider } from '../../../utils/hooks/useSlider';

import {
  StyledCarousel,
  StyledControls,
  StyledButtonLeft,
  StyledButtonRight,
  StyledInnerCarousel,
  StyledSlide,
} from './styles';

type Slide = {
  // Should start from 1.
  id: number;
  src: string;
};

type CarouselData = {
  data: Slide[];
};

const CarouselSlider = ({ data }: CarouselData) => {
  const { slideRight, slideLeft, animation, isActive } = useSlider(data.length);

  useEffect(() => {
    const fiveSeconds = 5000;
    const autoSlide = setInterval(() => {
      slideLeft();
    }, fiveSeconds);
    return () => {
      clearInterval(autoSlide);
    };
  });

  return (
    <StyledCarousel>
      <StyledControls>
        <StyledButtonRight aria-label="right slide" onClick={slideRight} />
        <StyledButtonLeft aria-label="left slide" onClick={slideLeft} />
      </StyledControls>
      <StyledInnerCarousel>
        {data.map((slide) => (
          <AnimatePresence key={slide.id}>
            <StyledSlide
              alt={`banner ${slide.id}`}
              animate={animation(slide.id)}
              isActive={isActive(slide.id)}
              src={slide.src}
              transition={slideDelay}
            />
          </AnimatePresence>
        ))}
      </StyledInnerCarousel>
    </StyledCarousel>
  );
};

export default CarouselSlider;
