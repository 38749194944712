import { useEffect, useState } from 'react';

import SelectableList from '../../components/ui/SelectableList';
import type { SelectableListItemProps } from '../../components/ui/SelectableList';
import LinkContent from '../../components/ui/SelectableList/LinkContent';
import { getProtocols } from '../../utils/api/protocols';
import LoadingScreen from '../../components/ui/LoadingScreen';

import ProtocolsDescription from './Description';
import { StyledBottomSide } from './styles';

const ProtocolsPage = () => {
  const [protocolsInfo, setProtocolsInfo] = useState<SelectableListItemProps[]>();

  useEffect(() => {
    const getProtocolsInfo = async () => {
      const { data } = await getProtocols();
      if (data) {
        setProtocolsInfo(data);
      }
    };
    void getProtocolsInfo();
  }, []);

  if (protocolsInfo === undefined) {
    return <LoadingScreen fullpage />;
  }
  return (
    <>
      <ProtocolsDescription />
      <StyledBottomSide>
        <SelectableList content={protocolsInfo}>
          <LinkContent />
        </SelectableList>
      </StyledBottomSide>
    </>
  );
};

export default ProtocolsPage;
