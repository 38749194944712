import AuthBox from '../../../components/ui/AuthBox';
import ChangePasswordForm from '../../Forms/ChangePassword';

const ChangePasswordPage = () => (
  <AuthBox>
    <ChangePasswordForm />
  </AuthBox>
);

export default ChangePasswordPage;
