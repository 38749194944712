import { Outlet } from 'react-router-dom';

import Footer from '../../components/ui/Footer';
import Navbar from '../../components/ui/Navbar';

import { StyledLayout, StyledPageContent } from './styles';

const Layout = () => (
  <StyledLayout>
    <Navbar />
    <StyledPageContent>
      <Outlet />
    </StyledPageContent>
    <Footer />
  </StyledLayout>
);

export default Layout;
